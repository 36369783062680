<template>
  
  <div>
    <navigation-bar></navigation-bar>
    <!-- Der Rest Ihres Komponenten-Templates -->
  </div>

    <!-- Main Content -->
    <div class="container-fluid mt-2">
      <h1 style="margin-top: 0px;">
        <i class="fas fa-plus-circle"></i> 
        Fuvarterv rögzítése</h1>
  
      <form id="orderForm" @submit.prevent="mentesx">
            <!-- Existing input-group sections for other filters and dropdowns -->

<!-- Megrendelő *************************************************************************************************** -->
<div class="form-group row align-items-center">
  <label for="megrendelo_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelő</label>

  <!-- Select2 Dropdown -->
  <div class="col-sm-2 col-md-2">
    <select id="megrendelo_select" class="form-control"></select>
  </div>
</div>


<div class="form-group row align-items-center">
  <!-- Felrakó -->
  <label for="felrako_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Felrakó</label>
  <div class="col-sm-2 col-md-2">
    <select id="felrako_select" class="form-control"></select>
  </div>
<!-- Felrakó hely -->
<label for="felhely_select" class="col-form-label col-md-1 d-flex align-items-center"><i class="fas fa-arrow-right"></i>Felrakó hely</label>
  <div class="col-md-2">
    <select id="felhely_select" class="form-control"></select>
  </div>
</div>

<!-- Felrakó und Lerakó hely in einer Zeile -->

<div class="form-group row align-items-center">

 <!-- Lerakó -->
 <label for="lerako_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Lerakó</label>
  <div class="col-sm-2 col-md-2">
    <select id="lerako_select" class="form-control"></select>
  </div>

  
  <!-- Lerakó hely -->
  <label for="lerakohely_select" class="col-form-label col-md-1 d-flex align-items-center">  <i class="fas fa-arrow-right"></i>  Lerakó hely</label>
  <div class="col-md-2">
    <select id="lerakohely_select" class="form-control"></select>
  </div>
</div>



<!-- Cikk -->
<div class="form-group row align-items-center">
  <label for="cikk_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Cikk</label>

  <!-- Select2 Dropdown -->
  <div class="col-sm-2 col-md-2">
    <select id="cikk_select" class="form-control"></select>
  </div>
</div>


<!--   Mennyiség ***************************************************************************************************************-->
<div class="form-group row align-items-center">
    <label for="mennyiseg" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Mennyiség</label>
    <div class="col-sm-2 col-md-2">
      <input type="number" 
        class="form-control" 
        id="mennyiseg" 
        v-model="suly_ertek" 
        placeholder="tonna" 
        max="5000" 
        @input="checkMennyiseg"
        @focus="clear_to"
        required >
    </div>
  </div>
<!--   km ***************************************************************************************************************-->
<div class="form-group row align-items-center">
    <label for="tavolsag" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Távolság</label>
    <div class="col-sm-2 col-md-2">
      <input type="number" class="form-control" id="tavolsag" step="0.001" v-model="km_ertek" placeholder="km" required @focus="clear_km">
    </div>
  </div>



<!-- szallitas_kezdete ************************************************************************************************************ -->
<div class="form-group row align-items-top">
  <label for="datum" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Szállítás kezdete</label>
  <div class="col-md-2">
    <input type="date" class="form-control" id="datum" v-model="datum_ertek" required>
  </div>

  <div class="col-md-2 d-flex justify-content-start">
    <button class="btn btn-primary mr-2" @click="setToday_datum">Mai nap</button>
    <button class="btn btn-secondary" @click="incrementDay_datum">+1 nap</button>
  </div>

</div>

<!-- határidő ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="hatarido" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Száll. határideje</label>
  <div class="col-md-2">
    <input type="date" class="form-control" id="hatarido" v-model="hatarido_ertek" >   <!--required-->
  </div>

  <div class="col-md-2 d-flex justify-content-start">
    <button class="btn btn-primary mr-2" @click="setToday">Mai nap</button>
    <button class="btn btn-secondary" @click="incrementDay">+1 nap</button>
  </div>
</div>


<!-- hivatkozási szám ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="hivatkozasi_szam" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Hivatkozási Szám</label>
  <div class="col-md-2">
    <input type="text" class="form-control" id="hivatkozasi_szam" v-model="hivatkozasi_szam_ertek">
  </div>
</div>

<!-- gmp  -->
<div class="form-group row align-items-center">
  <label for="gmp" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">GMP</label>
  <div class="col-md-2">
    <input type="checkbox" id="gmp" v-model="gmpValue">
  </div>
</div>

<!-- Megrendelői ár und Megrendelői ártípus in einer Zeile -->
<div class="form-group row align-items-center">
  <!-- Megrendelői ár -->
  <label for="megrendelo_ar" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelői ár</label>
  <div class="col-md-2">
    <input type="number" class="form-control" id="megrendelo_ar" step="0.01" v-model="megrendelo_ar_ertek" @focus="clear_ar">
  </div>

  <!-- Megrendelői ártípus -->
  <label for="tip_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Nagyár ártípus</label>
  <div class="col-sm-2 col-md-2">
    <select id="tip_select" class="form-control"></select>
  </div>
</div>


<!-- Alapértelm. alv díj und Alvállalkozói ártípus in einer Zeile -->
<div class="form-group row align-items-center">
  <!-- Alapértelm. alv díj -->
  <label for="alv_dij" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Alapért. alv díj</label>
  <div class="col-md-2">
    <input type="number" class="form-control" id="alv_dij" step="0.01" v-model="alv_dij_ertek" @focus="clear_alvar">
  </div>

  <!-- Alvállalkozói ártípus -->
  <label for="alvTip_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Kisár ártípus</label>
  <div class="col-sm-2 col-md-2">
    <select id="alvTip_select" class="form-control"></select>
  </div>
</div>


<!-- megjegyzes ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="megjegyzes" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megjegyzés</label>
  <div class="col-md-8">
    <input type="text" class="form-control" id="megjegyzes" v-model="megjegyzes">
  </div>
</div>

<!-- belső hivatkozási szám ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="belsoHivatkozas" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Belső hiv.szám</label>
  <div class="col-md-2">
    <input type="text" class="form-control" id="belsoHivatkozas" v-model="belsoHivatkozas_ertek">
  </div>
  (Azonos értékhez tartozó fuvarok össze lesznek vezetve a VIR-ben.)
</div>



<!--    mentés  *********************************************************************************-->                      
        <div class="form-group btn-container">
            <button type="button" class="btn btn-danger me-5" @click="abbrechen">Mégse</button>
            <button type="submit" class="btn btn-success" @click="mentes">Mentés</button>
            
        </div>



  <div id="message" class="mt-3"></div>

</form>
</div>


</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';


export default {
  name: 'NewOrder',

    data(){
       return{
                megrendeloOptions: [],
                felrakoOptions: [],
                lerakoOptions: [],
                cikkOptions: [],
                felhelyOptions: [],
                lehelyOptions: [],
                tipOptions: [], // Data property for ar_tipus
                valasz: [],
              //  megrendeloStandardValues: [], // Daten für Megrendelo
              //  felrakoStandardValues: [], // Daten für Felrako
              //  lerakoStandardValues: [], // Daten für Lerako
              //  cikkStandardValues: [], // Daten für Cikk
                selectedMegrendelo: null, // Variable zur Speicherung der ausgewählten Option
                selectedMegrendeloName: '',
                selectedFelrako: null,
                selectedFelrakoName: '',
                selectedLerako: '',
                selectedLerakoName: '',
                selectedCikk:0,
                selectedCikkName: '',
                selectedTip: null,
                selectedTipName: '',
                selectedFelHely:0,
                selectedFelHelyName: '',
                selectedLeHely:0,
                selectedLeHelyName: '',
              //  selectedMegrendeloVnev1:'',
              //  selectedFelrakoMegn:'',
              //  selectedLerakoMegn:'',
              //  selectedCikkMegn:'',
              //  selectedTipMegn:'',
                selectedTipMenny: 1,
              //  felhelyCeg_Cim:'',
              //  lehelyCeg_Cim:'',
                  suly_ertek: 0,
                  km_ertek: 0,
                  hivatkozasi_szam_ertek: '',
                  belsoHivatkozas_ertek:'',
                  megrendelo_ar_ertek: 0,
                  alv_dij_ertek: 0,     
                  hatarido_ertek: null,
                  datum_ertek: null,
                  megjegyzes:'',

                alvTipOptions: [], // Optionen für Alvállalkozói ártípus
                selectedAlvTip: null, // Ausgewählte Option
              //  selectedAlvTipMegn: '',
                selectedAlvTipName: '', // Name der ausgewählten Option   nem kell
              //  mar_artipaz: 0, // ID des ausgewählten Alvállalkozói ártípus
              //  mar_egysegarme: '', // Preis des ausgewählten Alvállalkozói ártípus
                gmpValue: false  // Standardwert: false (Nein)

       } 
    },
    methods: {



                abbrechen() {
                        if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
                            this.$router.push('/menu'); 
                        }
                },

                async mentes(){//****************************************************************************************************************** */
                
                    if (
                           !this.selectedMegrendelo 
                        || !this.selectedFelrako 
                        || !this.selectedLerako 
                        || !this.selectedCikk 
                        || !this.selectedTip 
                        || !this.suly_ertek 
                        || !this.km_ertek 
                        || !this.megrendelo_ar_ertek 
                        || !this.alv_dij_ertek 
                        || !this.hatarido_ertek
                        || !this.datum_ertek 
                        || !this.selectedAlvTip // Validierung für Alvállalkozói ártípus hinzugefügt
                        
                                          
                      ) {
                        alert("Az adatmegadás hiányos.");
                        return;
                    }

                    console.log('Inputs sind gesetzt.');

                    let suly = parseFloat(this.suly_ertek) || 0;
  
                // Wenn der Wert von 'mennyiseg' größer als 200 ist, zeige eine Warnung
                if (suly > 200) {
                  let confirmation = confirm("A mennyiség meghaladja a 200 tonnát. Biztosan folytatni akarja?");
                  if (!confirmation) {
                    return; // Falls der Nutzer "Nem" wählt, bricht die Methode hier ab
                  }
                }

                   
                    
                  
                    this.getSelectedTipMenny();
                  
                    this.getSelectedFelHelyMegn();
                    this.getSelectedLeHelyMegn();

                    console.log('this.slelectedTipMenny'+this.selectedTipMenny);

                    console.log('felhely',this.selectedFelHely);

                        let selectedTipMenny = parseFloat(this.selectedTipMenny) || 1;
                        let msuly = parseFloat(this.suly_ertek) || 0;

                        let statmenny = selectedTipMenny * msuly;
                    console.log('statmenny',statmenny);                        

                      



                    const postData={
                        m_az:0, 
                        m_megrendeloaz: this.selectedMegrendelo, 
                        megrendelo:this.selectedMegrendeloName, 
                        m_felrakoaz: this.selectedFelrako, 
                        felrako:this.selectedFelrakoName, 
                        m_lerakoaz: this.selectedLerako, 
                        lerako:this.selectedLerakoName, 
                        m_datumtol:this.datum_ertek, 
                        m_cikkaz:this.selectedCikk, 
                        cikk:this.selectedCikkName,
                        m_suly:this.suly_ertek, 
                        m_km:this.km_ertek, 

                        m_felhelyaz:this.selectedFelHely, 
                        fhely:this.selectedFelHelyName, 
                        
                        m_lehelyaz: this.selectedLeHely, 
                        lhely:this.selectedLeHelyName, 
                        
                        szall_hatarido:this.hatarido_ertek, 
                        hivatkozasi_szam: this.hivatkozasi_szam_ertek, 
                        m_artipaz: this.selectedTip, 
                        m_egysegarme:this.selectedTipName, 
                        m_aregysegar:this.megrendelo_ar_ertek, 
                        mar_egysegar:this.alv_dij_ertek, 
                        m_fuvarszaz:this.szem_az, 
                        fuvarszervezo:this.fsz, 
                        storno:0, 
                        m_cegaz:this.ceg_az,
                        megjegyzes:this.megjegyzes,
                        felh: this.fsz,
                        mod_felh: this.fsz,
                        stat_menny:   statmenny,
                        m_tipmenny: this.selectedTipMenny,
                          
                        mar_artipaz: parseInt(this.selectedAlvTip, 10), 
                        mar_egysegarme: this.selectedAlvTipName,
                        csoport: this.csoport,
                        gmp: this.gmpValue ? -1 : 0,  // GMP-Wert: -1 für Ja, 0 für Nein
                        belsoHivatkozas: this.belsoHivatkozas_ertek
                    };

                        //Logging postData before sending the request
                        console.log('Post Data:', JSON.stringify(postData));

                        try {

                            console.log('start axios');

                            const response = await axiosInstance.post('/megrendeles/api_create_megrendeles.php', postData);

                            console.log('Axios request completed');

                            this.valasz = response.data;
                            console.log('Status:', response.status);
                            console.log('Status Text:', response.statusText);
                            console.log('Headers:', response.headers);
                            console.log('Data:', response.data);

                            if (response.data.success) {
                                console.log('minden ok, az uj ID:', response.data.id);

                                this.$store.commit('setNapiDatum', this.datum_ertek);
                                // Leite zur Seite "osszesMegrendeles" weiter
                                this.$router.push({ name: 'osszesMegrendeles'});

                         //       alert("Az adatok mentésre kerültek.");
                            //    this.mentesx(); 
                            } else {
                                console.log('Hiba.');
                                alert("Hiba történt a mentés során, értesítse a programozót.");
                            }

                            console.log('vege');
                            console.log(this.valasz);



                        } catch (error) {
                            if (error.response) {
                                // Server antwortete mit einem Statuscode, der außerhalb des Bereichs von 2xx liegt
                                console.error('Error Status:', error.response.status);
                                console.error('Error Data:', error.response.data);
                                console.error('Error Headers:', error.response.headers);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.response.status+" " +error.response.data+" "+error.response.headers);
                            } else if (error.request) {
                                // Anfrage wurde gesendet, aber keine Antwort erhalten
                                console.error('No Response:', error.request);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.request);
                            } else {
                                // Fehler bei der Erstellung der Anfrage
                                console.error('Error Message:', error.message);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.message);
                            }
                            console.error('Config:', error.config);
                            alert("Hiba történt a mentés során, értesítse a programozót. " +error.config);
                        }

                    console.log('mentes vege');


                    this.$router.push('/osszesMegrendeles');

                },


                selectMegrendelo(item) {
                    this.selectedMegrendelo = item.v_kod;
                    this.selectedMegrendeloName = item.v_nev1;
                },
                selectFelrako(item) {
                    this.selectedFelrako = item.tav_az;
                    this.selectedFelrakoName = item.tav_megn;
                },
                selectLerako(item) {
                    this.selectedLerako = item.tav_az;
                    this.selectedLerakoName = item.tav_megn;
                },
                selectCikk(item) {
                    this.selectedCikk = item.cikk_az;
                    this.selectedCikkName = item.cikk_megn;
                },
                selectFelHely(item) {
                  this.selectedFelHely = item.hely_az;
                  this.selectedFelHelyName = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
                },
                selectLeHely(item) {
                  this.selectedLeHely = item.hely_az;
                  this.selectedLeHelyName = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
                },
                selectTip(item) {
                  this.selectedTip = item.tip_az;
                  this.selectedTipName = `${item.tip_megn} - ${item.tip_egyseg}`;
                },

                setToday() {
                  const today = new Date();
                  this.hatarido_ertek = today.toISOString().substr(0, 10); // Setzt das aktuelle Datum
                },
                incrementDay() {
                  let date = new Date(this.hatarido_ertek);
                  date.setDate(date.getDate() + 1); // Erhöht das Datum um einen Tag
                  this.hatarido_ertek = date.toISOString().substr(0, 10);
                },
                setToday_datum() {
                  const today = new Date();
                  this.datum_ertek = today.toISOString().substr(0, 10); // Setzt das aktuelle Datum
                },
                incrementDay_datum() {
                  let date = new Date(this.datum_ertek);
                  date.setDate(date.getDate() + 1); // Erhöht das Datum um einen Tag
                  this.datum_ertek = date.toISOString().substr(0, 10);
                },
              /*
                getSelectedMegrendeloVnev1() {
                    const selected = this.megrendeloOptions.find(item => item.v_kod === this.selectedMegrendelo);
                    this.selectedMegrendeloVnev1 = selected ? selected.v_nev1 : '';
                },
                
                getSelectedFelrakoMegn() {
                    const selected = this.felrakoOptions.find(item => item.tav_az === this.selectedFelrako);
                    this.selectedFelrakoMegn = selected ? selected.tav_megn : '';
                },
                getSelectedLerakoMegn() {
                    const selected = this.lerakoOptions.find(item => item.tav_az === this.selectedLerako);
                    this.selectedLerakoMegn = selected ? selected.tav_megn : '';
                },
                
                getSelectedCikkMegn() {
                    const selected = this.cikkOptions.find(item => item.cikk_az === this.selectedCikk);
                    this.selectedCikkMegn = selected ? selected.cikk_megn: '';
                },
                
                getSelectedTipMegn() {
                    const selected = this.tipOptions.find(item => item.tip_az === this.selectedTip);
                    this.selectedTipMegn = selected ? selected.tip_megn  + ' - ' + selected.tip_egyseg: '';
                },
                
                getSelectedAlvTipMegn() {
                    if (this.alvTipOptions && this.alvTipOptions.length > 0) { // Sicherstellen, dass alvTipOptions definiert und nicht leer ist
                        const selected = this.alvTipOptions.find(item => item.tip_az === this.selectedAlvTip);
                        this.selectedAlvTipMegn = selected ? selected.tip_megn + ' - ' + selected.tip_egyseg : ''; // Setze den Wert oder Standardwert
                    } else {
                        console.error('AlvTipOptions ist nicht definiert oder leer.');
                        this.selectedAlvTipMegn = ''; // Setze einen Standardwert, wenn alvTipOptions undefined ist
                    }
                },
              */
                getSelectedTipMenny() {
                    const selected = this.tipOptions.find(item => item.tip_az === this.selectedTip);
                    this.selectedTipMenny = selected ? selected.m_tipmenny: 1;
                },
                 
                getSelectedFelHelyMegn(){
                    const selected = this.felhelyOptions.find(item => item.hely_az === this.selectedFelHely);
                    this.felhelyCeg_Cim = selected ? selected.hely_ceg +' - ' + selected.hely_cim : '';
                },
                getSelectedLeHelyMegn(){
                    const selected = this.lehelyOptions.find(item => item.hely_az === this.selectedLeHely);
                    this.lehelyCeg_Cim = selected ? selected.hely_ceg +' - ' + selected.hely_cim : '';
                },

/*                async fetchMegrendelo() {
                    const v_nev1_filter = document.getElementById('megrendelo_filter').value;
                    const postData = {
                        v_cegaz: this.ceg_az,
                        v_nev1_filter: v_nev1_filter
                    };
                    console.log(JSON.stringify(postData));
                    let url="/megrendelo/list_megrendelo.php";
                    const response = await axiosInstance.post(url, postData);
                    console.log('response',response);
                    if (response.status === 200) {
                        console.log('belepett');
                        this.megrendeloOptions = response.data.data;
                        console.log(this.megrendeloOptions);
                    } else {
                        console.log('nem 200');
                        this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                    }
                    
                },
*/
 /*               async fetchFelrako(){
                    const tav_megn_filter = document.getElementById('felrako_filter').value;
                    const postData = {
                        tav_cegaz: this.ceg_az,  // Fixierter Wert für v_cegaz
                        tav_megn_filter: tav_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))

                        let url="/tav/list_tav.php";
                        const response =await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.felrakoOptions = response.data.data;
                           // console.log(this.felrakoOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }

                 }, 
*/
/*                 async  fetchLerako(){
                    const tav_megn_filterle = document.getElementById('lerako_filter').value;
                    const postData = {
                        tav_cegaz: this.ceg_az,  // Fixierter Wert für v_cegaz
                        tav_megn_filter: tav_megn_filterle  // Dynamischer Wert aus dem Filter
                       };
                        //  console.log(JSON.stringify(postData))

                        let url="/tav/list_tav.php";
                        const response =await  axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.lerakoOptions = response.data.data;
                         //   console.log(this.lerakoOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }

                 }, 
*/                 
 /*                async  fetchCikk(){

                    const cikk_megn_filter = document.getElementById('cikk_filter').value;
                    const postData = {
                        cikk_cegaz: this.ceg_az,  // Fixierter Wert für v_cegaz
                        cikk_megn_filter: cikk_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                        //  console.log(JSON.stringify(postData))

                        let url="/cikk/list_cikk.php";
                        const response =await  axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.cikkOptions = response.data.data;
                         //   console.log(this.cikkOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 
/*                 
                /* 
                 getTavAz() {
                        // Hier können Sie den tav_az-Wert weiterverarbeiten oder anzeigen
                    console.log('Selected tav_az:', this.selectedFelrako);
                    },
                  */
/*                async  fetchFelhely(){
                    const hely_tavaz = this.selectedFelrako;  // document.getElementById('selectedFelrako').value;
                    const postData = {
                        hely_tavaz: hely_tavaz  // Dynamischer Wert aus dem Filter
                       };
                      console.log(JSON.stringify(postData))
                        let url="/hely/list_hely.php";
                        const response = await axiosInstance.post(url, postData);
                        console.log("response",response);
                        if (response.status === 200) {
                            this.felhelyOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 
/*
/*                 async fetchLehely(){//**************************************************************************************** 
                    const Lhely_tavaz = this.selectedLerako;  // document.getElementById('selectedFelrako').value;
                    const postData = {
                        hely_tavaz: Lhely_tavaz  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))

                    let url="/hely/list_hely.php";
                        const response = await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.lehelyOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 
  */               
/*                 async fetchArTipus() {//************************************************************************************ 
                    const tip_megn_filter = document.getElementById('tip_filter').value;
                    const postData = {
                        tip_cegaz: 1,  // Fixierter Wert für v_cegaz
                        tip_megn_filter: tip_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))
                    let url="/tip/list_tip.php";
                        const response = await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.tipOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                },
*/

/*                async fetchAlvArTipus() {//********************************************************************************* 
                        const alvTip_megn_filter = document.getElementById('alvTip_filter').value;
                        const postData = {
                            tip_cegaz: 1,  // Fester Wert für den Firmenkunden
                            tip_megn_filter: alvTip_megn_filter  // Wert aus dem Filter-Input
                        };
                        console.log(JSON.stringify(postData));  // Debugging

                        let url = "/tip/list_tip.php";  // API-Endpunkt
                        try {
                            const response = await axiosInstance.post(url, postData);
                            if (response.status === 200) {
                                this.alvTipOptions = response.data.data;  // Daten in die Option-Liste laden
                                console.log('Erfolgreich geladen:', this.alvTipOptions);  // Debugging
                            } else {
                                console.error('Fehler beim Laden der Daten:', response.data.message);
                                this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.data.message;
                            }
                        } catch (error) {
                            console.error('Fehler bei der Anfrage:', error);
                            this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
                        }
                    },
*/
                    /*
                                        setStandardValueTip(standardIndex) {//***************************************************************** 
                                                        this.tipOptions = [];
                                                        let standardValue;

                                                        switch (standardIndex) {
                                                            case 1:
                                                                standardValue = {
                                                                    az: '2',
                                                                    tip_az: '1',
                                                                    tip_megn: 'Fuvar',
                                                                    tip_egyseg: 'Ft/to',
                                                                    tip_cegaz: '1',
                                                                    m_tipmenny:1
                                                                };
                                                                this.selectedTip = '1';
                                                                this.selectedTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                                break;
                                                            case 2:
                                                                standardValue = {
                                                                    az: '4',
                                                                    tip_az: '3',
                                                                    tip_megn: 'Fuvar',
                                                                    tip_egyseg: '€/to',
                                                                    tip_cegaz: '1',
                                                                    m_tipmenny:1
                                                                };
                                                                this.selectedTip = '3';
                                                                this.selectedTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                                break;
                                                            default:
                                                                standardValue = null;
                                                        }
                                                        if (standardValue) {
                                                            this.tipOptions.push(standardValue);
                                                        }
                                                    },
*/
/*
                                         setStandardValueAlvTip(standardIndex) {//****************************************************** 
                                                  this.alvTipOptions = [];
                                                  let standardValue;

                                                  switch (standardIndex) {
                                                      case 1:
                                                          standardValue = {
                                                              az: '2',
                                                              tip_az: '1',
                                                              tip_megn: 'Fuvar',
                                                              tip_egyseg: 'Ft/to',
                                                              tip_cegaz: '1',
                                                              m_tipmenny:1
                                                          };
                                                          this.selectedAlvTip = standardValue.tip_az; // Setze die ID
                                                          this.selectedAlvTipName = standardValue.tip_egyseg; // Setze die Einheit
                                                          break;
                                                      case 2:
                                                          standardValue = {
                                                              az: '4',
                                                              tip_az: '3',
                                                              tip_megn: 'Fuvar',
                                                              tip_egyseg: '€/to',
                                                              tip_cegaz: '1',
                                                              m_tipmenny:1
                                                          };
                                                          this.selectedAlvTip = standardValue.tip_az; // Setze die ID
                                                          this.selectedAlvTipName = standardValue.tip_egyseg; // Setze die Einheit
                                                          break;
                                                      default:
                                                          standardValue = null;
                                                  }
                                                  if (standardValue) {
                                                      this.alvTipOptions.push(standardValue);
                                                  }
                                              },
                                              selectAlvTip(itemalv) {//******************************************************** 
                                                this.selectedAlvTip = itemalv.tip_az; // Integer ID
                                                this.selectedAlvTipName = itemalv.tip_egyseg; // Einheitspreis als String
                                            },
*/
                              checkMennyiseg() {
                                  if (this.suly_ertek > 5000) {
                                    alert("A mennyiség nem lehet nagyobb, mint 5000!");
                                    this.suly_ertek = 0; // Zurücksetzen auf den maximal erlaubten Wert
                                    }
                              },
                              clear_to() { // Entferne den Parameter 'event'
                           
                                  this.suly_ertek = ''; 
                              },
                              clear_km() { // Entferne den Parameter 'event'
                           
                                  this.km_ertek = ''; 
                              },
                              clear_ar() { // Entferne den Parameter 'event'
                           
                              this.megrendelo_ar_ertek = ''; 
                            },
                            clear_alvar() { // Entferne den Parameter 'event'
                           
                           this.alv_dij_ertek = ''; 
                         },
            
      initSelect2() {
        const vm = this;

        $('#megrendelo_select').select2({
          placeholder: 'Megrendelő kiválasztása...',
          allowClear: true,
          minimumInputLength: 2,
          ajax: {
            url: 'https://bogir.hu/V2/api/megrendelo/list_megrendelo.php',
            type: 'POST',
            contentType: 'application/json', // Wichtig: Header für JSON-Format
            dataType: 'json',
            delay: 250,
            headers: {
              'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
            },
            data: function (params) {
              const payload = {
                search: params.term,
                ceg_az: vm.ceg_az, // Beispiel: ceg_az aus Vue-Instanz
              };

              // Debugging: Daten in der Konsole anzeigen
              console.log('Daten, die an den Endpunkt gesendet werden:', payload);

              return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
            },
            processResults: function (response) {
              // Debugging: API-Antwort prüfen
              console.log('Antwort vom Server:', response);

              if (response && response.data && Array.isArray(response.data)) {
                return {
                  results: response.data.map(item => ({
                    id: item.v_kod,
                    text: item.v_nev1,
                  })),
                };
              } else {
                console.error('Fehlerhafte API-Antwort:', response);
                return { results: [] };
              }
            },
            cache: true,
            error: function (jqXHR, textStatus, errorThrown) {
              // Debugging: Fehler in der Anfrage prüfen
              console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
            },
          },
        });

        // Event Listener: Auswahl speichern
        $('#megrendelo_select').on('select2:select', function (e) {
          const selectedData = e.params.data; // Enthält die ausgewählten Daten
          vm.selectedMegrendelo = selectedData.id; // Speichert die ID
          vm.selectedMegrendeloName = selectedData.text; // Speichert den Namen

          // Debugging: Zeigt die gespeicherten Werte in der Konsole
          console.log('Ausgewählte Megrendelo-ID:', vm.selectedMegrendelo);
          console.log('Ausgewählter Megrendelo-Name:', vm.selectedMegrendeloName);
        });

        // Event Listener: Zurücksetzen der Auswahl
        $('#megrendelo_select').on('select2:clear', function () {
          vm.selectedMegrendelo = null;
          vm.selectedMegrendeloName = '';

          // Debugging: Auswahl zurückgesetzt
          console.log('Auswahl wurde zurückgesetzt.');
        });
      },
//***************************************************************************** */
initSelect2Felrako() {
  const vm = this;

  $('#felrako_select').select2({
    placeholder: 'Felrakó kiválasztása...',
    allowClear: true,
    minimumInputLength: 2,
    ajax: {
      url: 'https://bogir.hu/V2/api/tav/list_tav.php', // Passe die API-URL an
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term,
          ceg_az: vm.ceg_az, // Beispiel: ceg_az aus Vue-Instanz
        };

        // Debugging: Daten in der Konsole anzeigen
        console.log('Daten, die an den Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tav_az,
              text: item.tav_megn,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        // Debugging: Fehler in der Anfrage prüfen
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#felrako_select').on('select2:select', function (e) {
    const selectedData = e.params.data; // Enthält die ausgewählten Daten
    vm.selectedFelrako = selectedData.id; // Speichert die ID
    vm.selectedFelrakoName = selectedData.text; // Speichert den Namen

    // Debugging: Zeigt die gespeicherten Werte in der Konsole
    console.log('Ausgewählte Felrakó-ID:', vm.selectedFelrako);
    console.log('Ausgewählter Felrakó-Name:', vm.selectedFelrakoName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#felrako_select').on('select2:clear', function () {
    vm.selectedFelrako = null;
    vm.selectedFelrakoName = '';

    // Debugging: Auswahl zurückgesetzt
    console.log('Auswahl wurde zurückgesetzt.');
  });
},

//******************************************************************************************* */
initSelect2Lerako() {
  const vm = this;

  $('#lerako_select').select2({
    placeholder: 'Lerakó kiválasztása...',
    allowClear: true,
    minimumInputLength: 2,
    ajax: {
      url: 'https://bogir.hu/V2/api/tav/list_tav.php', // Passe die API-URL an
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term,
          ceg_az: vm.ceg_az, // Beispiel: ceg_az aus Vue-Instanz
        };

        // Debugging: Daten in der Konsole anzeigen
        console.log('Daten, die an den Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tav_az,
              text: item.tav_megn,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        // Debugging: Fehler in der Anfrage prüfen
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#lerako_select').on('select2:select', function (e) {
    const selectedData = e.params.data; // Enthält die ausgewählten Daten
    vm.selectedLerako = selectedData.id; // Speichert die ID
    vm.selectedLerakoName = selectedData.text; // Speichert den Namen

    // Debugging: Zeigt die gespeicherten Werte in der Konsole
    console.log('Ausgewählte Lerakó-ID:', vm.selectedLerako);
    console.log('Ausgewählter Lerakó-Name:', vm.selectedLerakoName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#lerako_select').on('select2:clear', function () {
    vm.selectedLerako = null;
    vm.selectedLerakoName = '';

    // Debugging: Auswahl zurückgesetzt
    console.log('Auswahl wurde zurückgesetzt.');
  });
},

//******************************************************************************************* */
initCikkSelect2() {
  const vm = this;

  $('#cikk_select').select2({
    placeholder: 'Cikk kiválasztása...',
    allowClear: true,
    minimumInputLength: 2,
    ajax: {
      url: 'https://bogir.hu/V2/api/cikk/list_cikk.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // JSON-Header
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.ceg_az,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.cikk_az,       // Cikk-ID
              text: item.cikk_megn,   // Cikk-Name
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#cikk_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedCikk = selectedData.id; // Speichert die ID
    vm.selectedCikkName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Cikk-ID:', vm.selectedCikk);
    console.log('Ausgewählter Cikk-Name:', vm.selectedCikkName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#cikk_select').on('select2:clear', function () {
    vm.selectedCikk = null;
    vm.selectedCikkName = '';

    console.log('Cikk-Auswahl wurde zurückgesetzt.');
  });
},
//************************************************************************************************ */
initTipSelect2() {
  const vm = this;

  $('#tip_select').select2({
    placeholder: 'Ártípus kiválasztása...',
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      url: 'https://bogir.hu/V2/api/tip/list_tip.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.ceg_az,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tip_az,       // ID der ártípus
              text: `${item.tip_egyseg} - ${item.tip_megn}`, // Name + Einheit
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#tip_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedTip = selectedData.id; // Speichert die ID
    vm.selectedTipName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Tip-ID:', vm.selectedTip);
    console.log('Ausgewählter Tip-Name:', vm.selectedTipName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#tip_select').on('select2:clear', function () {
    vm.selectedTip = null;
    vm.selectedTipName = '';

    console.log('Ártípus-Auswahl wurde zurückgesetzt.');
  });
},

//******************************************************************************************************** */
initAlvTipSelect2() {
  const vm = this;

  $('#alvTip_select').select2({
    placeholder: 'Ártípus kiválasztása...',
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      url: 'https://bogir.hu/V2/api/tip/list_tip.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.ceg_az,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tip_az,       // ID der ártípus
              text: `${item.tip_egyseg} - ${item.tip_megn}`, // Name + Einheit
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#alvTip_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedAlvTip = selectedData.id; // Speichert die ID
    vm.selectedAlvTipName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Alvállalkozói ártípus-ID:', vm.selectedAlvTip);
    console.log('Ausgewählte Alvállalkozói ártípus-Name:', vm.selectedAlvTipName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#alvTip_select').on('select2:clear', function () {
    vm.selectedAlvTip = null;
    vm.selectedAlvTipName = '';

    console.log('Alvállalkozói ártípus-Auswahl wurde zurückgesetzt.');
  });
},
//******************************************************************************************************* */
initFelhelySelect2() {
  const vm = this;

  $('#felhely_select').select2({
    placeholder: 'Felrakó hely kiválasztása...', // Platzhaltertext
    allowClear: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/hely/list_hely.php', // API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function () {
        const payload = {
          hely_tavaz: vm.selectedFelrako || 0, // Optional: Abhängig von Felrakó
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.hely_az, // ID der Felrakó hely
              text: `${item.hely_ceg} - ${item.hely_cim}`, // Text für Dropdown
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#felhely_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedFelHely = selectedData.id; // Speichert die ID
    vm.selectedFelHelyName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Felhely-ID:', vm.selectedFelHely);
    console.log('Ausgewählter Felhely-Name:', vm.selectedFelHelyName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#felhely_select').on('select2:clear', function () {
    vm.selectedFelHely = null;
    vm.selectedFelHelyName = '';

    console.log('Felhely-Auswahl wurde zurückgesetzt.');
  });
},

//*********************************************************************************** */

initSelect2LerakoHely() {
  const vm = this;

  $('#lerakohely_select').select2({
    placeholder: 'Lerakó hely kiválasztása...',
    allowClear: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/hely/list_hely.php', // Endpunkt für Lerakó hely
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function () {
        const payload = {
          hely_tavaz: vm.selectedLerako // Hier wird der ausgewählte `lerako`-Wert verwendet
        };

        // Debugging: Zeige die gesendeten Daten in der Konsole an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.hely_az,
              text: `${item.hely_ceg} - ${item.hely_cim}`,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#lerakohely_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedLeHely = selectedData.id; // Speichert die ID
    vm.selectedLeHelyName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Lerakó hely-ID:', vm.selectedLeHely);
    console.log('Ausgewählter Lerakó hely-Name:', vm.selectedLeHelyName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#lerakohely_select').on('select2:clear', function () {
    vm.selectedLeHely = null;
    vm.selectedLeHelyName = '';

    console.log('Lerakó hely-Auswahl wurde zurückgesetzt.');
  });
},




   

// methods vége
    },
    computed: {
        ...mapGetters(['getUserData']),
        fsz() {
            return this.getUserData ? this.getUserData.fsz : null;
        },
        szem_az() {
            return this.getUserData ? this.getUserData.szem_az : null;
        },
        csoport() {
            return this.getUserData ? this.getUserData.csoport : null;
        },
        ceg_az() {
            return this.getUserData ? this.getUserData.ceg_az : null;
        }
  },

  mounted() {
    this.initSelect2();
  this.initSelect2Felrako();
  this.initSelect2Lerako(); // Initialisiert Lerakó
  this.initCikkSelect2(); // Cikk
  this.initTipSelect2(); // Megrendelői ártípus
  this.initAlvTipSelect2();  // Alvállalkozói ártípus
  this.initFelhelySelect2();
  this.initSelect2LerakoHely(); // Initialisiere Lerakó hely


}


};
</script>

<style scoped>




.container .container-fluid {
  padding-top: 0px;
  margin-top: 0px;
}

.container.mt-5 {
    margin-top: 0px !important; /* Setzt das Margin auf einen kleineren Wert */
}

h1 {
  margin-top: 0px !important; /* Verringere den Abstand nach Bedarf */
}

.form-group.row.align-items-center {
    margin-bottom: 2px !important; /* Setze das Margin auf 0 und überschreibe alle anderen Regeln */
}

.dropdown-menu {
  max-height: 500px; /* Maximale Höhe der Dropdown-Liste */
  overflow-y: auto; /* Aktiviert die vertikale Scrollbar */
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
  max-width: 100%; /* Breite der Dropdown-Liste bleibt gleich */
  white-space: nowrap; /* Kein Zeilenumbruch innerhalb der Items */
  text-overflow: ellipsis; /* Textüberlauf mit "..." anzeigen */
}

.dropdown-item {
  max-width: 100%; /* Breite der einzelnen Dropdown-Items begrenzen */
  white-space: nowrap; /* Kein Zeilenumbruch für Dropdown-Items */
  overflow: hidden; /* Überlaufenden Text ausblenden */
  text-overflow: ellipsis; /* Überlaufenden Text mit "..." anzeigen */
}



.selected-megrendelo,
.selected-felrako,
.selected-lerako {
  font-weight: bold;
  color: darkgreen;
}

.custom-btn-sm {
  padding: 2px 4px; /* Minimaleres Padding für eine engere Passform */
  font-size: 12px;
  line-height: 1.2;
}

.form-group.row {
  align-items: flex-end; /* Elemente am unteren Rand ausrichten */
  margin-bottom: 8px; /* Vermeiden Sie zu viel Außenabstand */
}



.btn-group button, .dropdown-toggle {
  height: 36px; /* Lassen Sie die Höhe sich natürlich anpassen */
  padding: 6px 12px; /* Standard-Padding für Bootstrap-Buttons, anpassen falls nötig */
}

.dropdown-toggle {
  align-self: stretch; /* Lässt den Dropdown-Button die Höhe seiner Nachbarn annehmen */
}

.input-group .form-control {
  height: auto; /* Vermeiden Sie feste Höhenangaben, falls diese Probleme verursachen */
}

.input-group-append {
  margin-left: 4px; /* Kleiner Abstand zwischen Eingabefeld und Button */
}
</style>
