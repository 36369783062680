<template>
  <div>
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container-fluid mt-2">
      <h1>
        <i class="fas fa-edit"></i> Megrendelés szerkesztése
      </h1>

      <form id="editOrderForm" v-if="megrendeles" @submit.prevent="saveOrder">
      
      
        <!-- Megrendelő -->
        <div class="form-group row align-items-center">
          <label for="megrendelo_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelő</label>
          <!-- Eingabefeld mit aktuellem Wert -->
          <div class="col-sm-2 col-md-2">
            <input type="text" class="form-control" :value="megrendeles.megrendelo" readonly>
          </div>
          <!-- Select2 Dropdown -->
          <div class="col-sm-2 col-md-2">
            <select id="megrendelo_select" class="form-control"></select>
          </div>
        </div>



          <!-- Felrakó -->
          <div class="form-group row align-items-center">
            <label for="felrako_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Felrakó</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.felrako" readonly>
            </div>
            <div class="col-sm-2 col-md-2">
              <select id="felrako_select" class="form-control"></select>
            </div>
          </div>


          <!-- Felrakó hely-->
          <div class="form-group row align-items-center">
            <label for="felhely_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Felrakó hely</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.fhely" readonly>
            </div>
            <div class="col-sm-2 col-md-2">
              <select id="felhely_select" class="form-control"></select>
            </div>
          </div>







          <!-- Lerakó -->
        <div class="form-group row align-items-center">
          <label for="lerako_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Lerakó</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.lerako" readonly>
            </div>
            <div class="col-sm-2 col-md-2">
              <select id="lerako_select" class="form-control"></select>
            </div>
          </div>
        
   <!-- Lerakó hely-->
   <div class="form-group row align-items-center">
          <label for="lerakohely_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Lerakó hely</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.lhely" readonly>
            </div>
            <div class="col-sm-2 col-md-2">
              <select id="lerakohely_select" class="form-control"></select>
            </div>
          </div>

          <!-- Cikk -->
          <div class="form-group row align-items-center">
          <label for="cikk_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Cikk</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.cikk" readonly>
            </div>
            <div class="col-sm-2 col-md-2">
              <select id="cikk_select" class="form-control"></select>
            </div>
          </div>

 <!-- mennyiseg -->
        <div class="form-group row align-items-center">
          <label for="mennyiseg" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Mennyiség</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" class="form-control" :value="megrendeles.m_suly" readonly>
            </div>
          </div>

<!-- km -->
<div class="form-group row align-items-center">
  <label for="tavolsag" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Távolság</label>
  
  <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" :value="megrendeles.m_km" readonly>
  </div>
  
  <!-- Neues Feld zur Eingabe -->
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" id="tavolsag_neu"   step="0.01"    v-model="newKm" placeholder="Új távolság">
  </div>
</div>

<!-- szállítás kezdete -->
<div class="form-group row align-items-center">
  <label for="ido" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Szállítás kezdete</label>
    <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="date" class="form-control" :value="megrendeles.m_datumtol" readonly>
  </div>
  <label for="ido2" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">határideje</label>
    <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="date" class="form-control" :value="megrendeles.szall_hatarido" readonly>
  </div>

  </div>


  <!-- hivatkozási szám -->
<div class="form-group row align-items-center">
  <label for="hivatkozasi_szam" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Hivatkozási szám</label>
  
  <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" :value="megrendeles.hivatkozasi_szam" readonly>
  </div>
  
  <!-- Neues Feld zur Eingabe -->
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" id="hivatkozasi_szam" v-model="newHivatkozasi_szam" placeholder="Új hivatkozási szám">
  </div>
</div>


<!-- GMP -->
<div class="form-group row align-items-center">
  <label for="gmp" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">GMP</label>
  
  <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="checkbox" :checked="megrendeles.gmp == -1" disabled>
  </div>
  
  <!-- Neues Feld zur Eingabe -->
  <div class="col-sm-2 col-md-2">
    <input type="checkbox" id="gmp" v-model="newGMP">
  </div>
</div>


<!-- Megrendelői ár -->
<div class="form-group row align-items-center">
  <label for="megrendelo_ar" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelői ár</label>
  
  <!-- Altes Feld (readonly) -->
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" :value="megrendeles.m_aregysegar" readonly>
  </div>
  
  <!-- Neues Feld zur Eingabe -->
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" id="megrendelo_ar" step="0.01"  v-model="newMegrendeloAr" placeholder="Új nagyár">
  </div>
</div>



<!-- Megrendelői ártípus -->
<div class="form-group row align-items-center">
  <!-- Altes Feld (readonly) -->
  <label for="tip_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megr. ártípus</label>
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" :value="megrendeles.m_egysegarme" readonly>
  </div>

  <!-- Neues Feld zur Auswahl -->
  <div class="col-sm-2 col-md-2">
    <select id="tip_select" class="form-control"></select>
  </div>
</div>


<!-- Alvállalkozói ár -->
<div class="form-group row align-items-center">
  <!-- Altes Feld (readonly) -->
  <label for="alv_ar" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Alváll. ár</label>
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" :value="megrendeles.mar_egysegar" readonly>
  </div>

  <!-- Neues Feld zur Eingabe -->
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" id="alv_ar" step="0.01" v-model="newAlvAr" placeholder="Új kisár">
  </div>
</div>

<!-- Alvállalkozói ártípus -->
<div class="form-group row align-items-center">
  <!-- Altes Feld (readonly) -->
  <label for="alvTip_select" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Alv. ártípus</label>
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" :value="megrendeles.mar_egysegarme" readonly>
  </div>

  <!-- Neues Feld zur Auswahl -->
  <div class="col-sm-2 col-md-2">
    <select id="alvTip_select" class="form-control"></select>
  </div>
</div>

<!-- Megjegyzés -->
<div class="form-group row align-items-center">
  <!-- Altes Feld (readonly) -->
  <label for="megjegyzes_alt" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megjegyzés</label>
  <div class="col-sm-3 col-md-3">
    <input type="text" class="form-control" :value="megrendeles.megjegyzes" readonly>
  </div>

  <!-- Neues Feld -->
  
  <div class="col-sm-3 col-md-3">
    <input type="text" class="form-control" v-model="newMegjegyzes" placeholder="Új megjegyzés">
  </div>
</div>

<!-- Belső Hivatkozás -->
<div class="form-group row align-items-center">
  <!-- Altes Feld (readonly) -->
  <label for="belsoHivatkozas_alt" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Belső hiv.szám</label>
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" :value="megrendeles.belsoHivatkozas" readonly>
  </div>

  <!-- Neues Feld -->
 
  <div class="col-sm-2 col-md-2">
    <input type="text" class="form-control" v-model="newBelsoHivatkozas" placeholder="Új belső hivatkozási szám">
  </div>
</div>







        <!-- Buttons -->
        <div class="form-group btn-container">
          <button type="button" class="btn btn-danger" @click="cancel">Mégse</button>
          <button type="submit" class="btn btn-success">Mentés</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axiosInstance";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      megrendeles: null,
      selectedMegrendelo: null,
      selectedMegrendeloName: "",
      selectedFelrako: null,
        selectedFelrakoName: '',
        selectedLerako: null,
        selectedLerakoName: '',
        ceg_az: null, // Stellen Sie sicher, dass ceg_az initialisiert wird
        selectedFelHely:null,
        selectedFelHelyName: '',
        selectedLeHely:null,
        selectedLeHelyName: '',
        selectedCikk:null,
        selectedCikkName: '',
        newKm: '', // Neues Feld für den neuen Wert
        newM_datumtol:null,
        newHivatkozasi_szam:'',
        newMegrendeloAr:null,
        selectedTip: null, // Neue ausgewählte Ártípus-ID
        selectedTipName: '', // Neuer ausgewählter Ártípus-Name
        newAlvAr:null,
        selectedAlvTip : null,
        selectedAlvTipName: '',
        newMegjegyzes: '', // Neues Megjegyzés-Feld
        newBelsoHivatkozas: '', // Neues Belső Hivatkozás-Feld

    };
  },
  mounted() {
    this.fetchOrderDetails();

    this.fetchOrderDetails().then(() => {
    this.$nextTick(() => {
      this.initSelect2();
      this.initSelect2Felrako();
      this.initSelect2Lerako();
      this.initFelhelySelect2();
      this.initSelect2LerakoHely();
      this.initCikkSelect2();
      this.initTipSelect2();
      this.initAlvTipSelect2(); // Füge die Initialisierung hinzu


    });
  });


  },
  computed: {
    ...mapGetters(["getorderData"]),
    az() {
      return this.getorderData?.az || null;
    },
  },
  methods: {
    async fetchOrderDetails() {
      const postData = {
        az: this.az, // ID der Bestellung
      };

      try {
        console.log("Laden der Bestellung:", postData);
        const url = "/megrendeles/egy_megrendeles.php";
        const response = await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          this.megrendeles = response.data.data[0];
          console.log("Bestelldaten geladen:", this.megrendeles);

          // Select2 initialisieren
          this.initSelect2();
        } else {
          console.error("Fehler beim Abrufen der Bestelldaten:", response);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Bestelldaten:", error);
      }
    },


//**************************************************************************************** */
    initSelect2() {
      const vm = this;

      // Select2 Dropdown initialisieren
      $("#megrendelo_select").select2({
        placeholder: "Megrendelő kiválasztása...",
        allowClear: true,
        minimumInputLength: 2,
        ajax: {
          url: "https://bogir.hu/V2/api/megrendelo/list_megrendelo.php",
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          delay: 250,
          headers: {
            Authorization: "Basic " + btoa("Admin_2024$$:S3cure+P@ssw0rd2024!"),
          },
          data: function (params) {
            const payload = {
              search: params.term,
              ceg_az: vm.megrendeles?.m_cegaz || 0,
            };
            console.log("Gesendete Daten:", payload);
            return JSON.stringify(payload);
          },
          processResults: function (response) {
            console.log("Empfangene Daten:", response);
            if (response && response.data && Array.isArray(response.data)) {
              return {
                results: response.data.map((item) => ({
                  id: item.v_kod,
                  text: item.v_nev1,
                })),
              };
            } else {
              console.error("Fehlerhafte Antwort:", response);
              return { results: [] };
            }
          },
          cache: true,
        },
      });

   
      // Event Listener für Auswahl
      $("#megrendelo_select").on("select2:select", function (e) {
        const selectedData = e.params.data;
        vm.selectedMegrendelo = selectedData.id;
        vm.selectedMegrendeloName = selectedData.text;
        console.log("Ausgewählte Megrendelo-ID:", vm.selectedMegrendelo);
        console.log("Ausgewählter Megrendelo-Name:", vm.selectedMegrendeloName);
      });

      // Auswahl zurücksetzen
      $("#megrendelo_select").on("select2:clear", function () {
        vm.selectedMegrendelo = null;
        vm.selectedMegrendeloName = "";
        console.log("Auswahl wurde zurückgesetzt.");
      });
    },

//*********************************************************************************************************************** */
initSelect2Felrako() {
    const vm = this;

    $('#felrako_select').select2({
      placeholder: 'Felrakó kiválasztása...',
      allowClear: true,
      minimumInputLength: 2,
      ajax: {
        url: 'https://bogir.hu/V2/api/tav/list_tav.php',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        delay: 250,
        headers: {
          'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
        },
        data: function (params) {
          return JSON.stringify({
            search: params.term,
            ceg_az: vm.megrendeles?.m_cegaz || 0,
          });
        },
        processResults: function (response) {
          if (response?.data?.length) {
            return {
              results: response.data.map(item => ({
                id: item.tav_az,
                text: item.tav_megn,
              })),
            };
          } else {
            return { results: [] };
          }
        },
        cache: true,
      },
    });

    $('#felrako_select').on('select2:select', function (e) {
      const selectedData = e.params.data;
      vm.selectedFelrako = selectedData.id;
      vm.selectedFelrakoName = selectedData.text;
    });

    $('#felrako_select').on('select2:clear', function () {
      vm.selectedFelrako = null;
      vm.selectedFelrakoName = '';
    });
  },

  //**************************************************************** */
  initSelect2Lerako() {
    const vm = this;

    $('#lerako_select').select2({
      placeholder: 'Lerakó kiválasztása...',
      allowClear: true,
      minimumInputLength: 2,
      ajax: {
        url: 'https://bogir.hu/V2/api/tav/list_tav.php',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        delay: 250,
        headers: {
          'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
        },
        data: function (params) {
          return JSON.stringify({
            search: params.term,
            ceg_az: vm.megrendeles?.m_cegaz || 0,
          });
        },
        processResults: function (response) {
          if (response?.data?.length) {
            return {
              results: response.data.map(item => ({
                id: item.tav_az,
                text: item.tav_megn,
              })),
            };
          } else {
            return { results: [] };
          }
        },
        cache: true,
      },
    });

    $('#lerako_select').on('select2:select', function (e) {
      const selectedData = e.params.data;
      vm.selectedLerako = selectedData.id;
      vm.selectedLerakoName = selectedData.text;
    });

    $('#lerako_select').on('select2:clear', function () {
      vm.selectedLerako = null;
      vm.selectedLerakoName = '';
    });
  },

//*************************************************************************************************************** */
initFelhelySelect2() {
  const vm = this;

  $('#felhely_select').select2({
    placeholder: 'Felrakó hely kiválasztása...', // Platzhaltertext
    allowClear: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/hely/list_hely.php', // API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function () {
        const payload = {
          hely_tavaz: vm.selectedFelrako || 0, // Optional: Abhängig von Felrakó
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.hely_az, // ID der Felrakó hely
              text: `${item.hely_ceg} - ${item.hely_cim}`, // Text für Dropdown
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#felhely_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedFelHely = selectedData.id; // Speichert die ID
    vm.selectedFelHelyName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Felhely-ID:', vm.selectedFelHely);
    console.log('Ausgewählter Felhely-Name:', vm.selectedFelHelyName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#felhely_select').on('select2:clear', function () {
    vm.selectedFelHely = null;
    vm.selectedFelHelyName = '';

    console.log('Felhely-Auswahl wurde zurückgesetzt.');
  });
},
//******************************************************************************************************************** */

initSelect2LerakoHely() {
  const vm = this;

  $('#lerakohely_select').select2({
    placeholder: 'Lerakó hely kiválasztása...',
    allowClear: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/hely/list_hely.php', // Endpunkt für Lerakó hely
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function () {
        const payload = {
          hely_tavaz: vm.selectedLerako // Hier wird der ausgewählte `lerako`-Wert verwendet
        };

        // Debugging: Zeige die gesendeten Daten in der Konsole an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.hely_az,
              text: `${item.hely_ceg} - ${item.hely_cim}`,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#lerakohely_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedLeHely = selectedData.id; // Speichert die ID
    vm.selectedLeHelyName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Lerakó hely-ID:', vm.selectedLeHely);
    console.log('Ausgewählter Lerakó hely-Name:', vm.selectedLeHelyName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#lerakohely_select').on('select2:clear', function () {
    vm.selectedLeHely = null;
    vm.selectedLeHelyName = '';

    console.log('Lerakó hely-Auswahl wurde zurückgesetzt.');
  });
},
//************************************************************************************************* */
initCikkSelect2() {
  const vm = this;

  $('#cikk_select').select2({
    placeholder: 'Cikk kiválasztása...',
    allowClear: true,
    minimumInputLength: 2,
    ajax: {
      url: 'https://bogir.hu/V2/api/cikk/list_cikk.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // JSON-Header
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.megrendeles.m_cegaz,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.cikk_az,       // Cikk-ID
              text: item.cikk_megn,   // Cikk-Name
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#cikk_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedCikk = selectedData.id; // Speichert die ID
    vm.selectedCikkName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Cikk-ID:', vm.selectedCikk);
    console.log('Ausgewählter Cikk-Name:', vm.selectedCikkName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#cikk_select').on('select2:clear', function () {
    vm.selectedCikk = null;
    vm.selectedCikkName = '';

    console.log('Cikk-Auswahl wurde zurückgesetzt.');
  });
},
//************************************************************************************************ */
initTipSelect2() {
  const vm = this;

  $('#tip_select').select2({
    placeholder: 'Ártípus kiválasztása...',
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      url: 'https://bogir.hu/V2/api/tip/list_tip.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.ceg_az,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tip_az,       // ID der ártípus
              text: `${item.tip_egyseg} - ${item.tip_megn}`, // Name + Einheit
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#tip_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedTip = selectedData.id; // Speichert die ID
    vm.selectedTipName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Tip-ID:', vm.selectedTip);
    console.log('Ausgewählter Tip-Name:', vm.selectedTipName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#tip_select').on('select2:clear', function () {
    vm.selectedTip = null;
    vm.selectedTipName = '';

    console.log('Ártípus-Auswahl wurde zurückgesetzt.');
  });
},

  //*********************************************************************************************************** */
  initAlvTipSelect2() {
  const vm = this;

  $('#alvTip_select').select2({
    placeholder: 'Ártípus kiválasztása...',
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      url: 'https://bogir.hu/V2/api/tip/list_tip.php', // Dein API-Endpunkt
      type: 'POST',
      contentType: 'application/json', // Wichtig für JSON-Daten
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff aus dem Input
          ceg_az: vm.ceg_az,  // Übergibt den `ceg_az`-Wert
        };

        // Debugging: Zeige die gesendeten Daten an
        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload); // JSON-Daten korrekt serialisieren
      },
      processResults: function (response) {
        // Debugging: API-Antwort prüfen
        console.log('Antwort vom Server:', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tip_az,       // ID der ártípus
              text: `${item.tip_egyseg} - ${item.tip_megn}`, // Name + Einheit
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort:', response);
          return { results: [] };
        }
      },
      cache: true, // Zwischenspeichern aktivieren
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage:', textStatus, errorThrown);
      },
    },
  });

  // Event Listener: Auswahl speichern
  $('#alvTip_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedAlvTip = selectedData.id; // Speichert die ID
    vm.selectedAlvTipName = selectedData.text; // Speichert den Namen

    console.log('Ausgewählte Alvállalkozói ártípus-ID:', vm.selectedAlvTip);
    console.log('Ausgewählte Alvállalkozói ártípus-Name:', vm.selectedAlvTipName);
  });

  // Event Listener: Zurücksetzen der Auswahl
  $('#alvTip_select').on('select2:clear', function () {
    vm.selectedAlvTip = null;
    vm.selectedAlvTipName = '';

    console.log('Alvállalkozói ártípus-Auswahl wurde zurückgesetzt.');
  });
},
//****************************************************************************************************** */







  fetchCegAz() {
    // Laden Sie den Wert von ceg_az für beide Select2-Felder, falls nötig
    if (this.megrendeles) {
      this.ceg_az = this.megrendeles.m_cegaz || 0;
    }
  },

//**************************************************************************************************** */

async saveOrder() {
  // Erstelle ein Objekt für die geänderten Daten
  const changedData = {};

console.log('this.selectedFelHely)',this.selectedFelHely);
console.log('this.selectedFelHelyName)',this.selectedFelHelyName);

  // Prüfe und füge geänderte Werte hinzu
  if (this.selectedMegrendelo && this.selectedMegrendelo !== this.megrendeles.m_megrendeloaz) {
    changedData.m_megrendeloaz = this.selectedMegrendelo;
    changedData.megrendelo = this.selectedMegrendeloName;
  }

  if (this.selectedFelrako && this.selectedFelrako !== this.megrendeles.felrako) {
    changedData.m_felrakoaz = this.selectedFelrako;
    changedData.felrako = this.selectedFelrakoName;
  }

  if (this.selectedFelHely && this.selectedFelHely !== this.megrendeles.m_felhelyaz) {
    
    changedData.m_felhelyaz = this.selectedFelHely;
    changedData.fhely = this.selectedFelHelyName;
  }


  if (this.selectedLerako && this.selectedLerako !== this.megrendeles.lerako) {
    changedData.m_lerakoaz = this.selectedLerako;
    changedData.lerako = this.selectedLerakoName;
  }

  if (this.selectedLeHely && this.selectedLeHely !== this.megrendeles.m_lehelyaz) {
    changedData.m_lehelyaz = this.selectedLeHely;
    changedData.lhely = this.selectedLeHelyName;
  }


  if (this.selectedCikk && this.selectedCikk !== this.megrendeles.cikk) {
    changedData.m_cikkaz = this.selectedcikk;
    changedData.cikk = this.selectedCikkName;
  }


  if (this.newKm && this.newKm !== this.megrendeles.m_km) {
    changedData.m_km = this.newKm;
  }

  if (this.newHivatkozasi_szam && this.newHivatkozasi_szam !== this.megrendeles.hivatkozasi_szam) {
    changedData.hivatkozasi_szam = this.newHivatkozasi_szam;
  }

  if (this.newGMP !== undefined && this.newGMP !== (this.megrendeles.gmp === -1)) {
    changedData.gmp = this.newGMP ? -1 : 0;
  }

  if (this.newMegrendeloAr && this.newMegrendeloAr !== this.megrendeles.m_aregysegar) {
    changedData.m_aregysegar = this.newMegrendeloAr;
  }

  if (this.selectedTip && this.selectedTip !== this.megrendeles.m_tipaz) {
    changedData.m_artipaz = this.selectedTip;
    changedData.m_egysegarme = this.selectedTipName;
  }
  
  if (this.newAlvAr && this.newAlvAr !== this.megrendeles.mar_egysegar) {
    changedData.mar_egysegar = this.newAlvAr;
  }

  if (this.selectedAlvTip && this.selectedAlvTip !== this.megrendeles.mar_tipaz) {
    changedData.mar_artipaz = this.selectedAlvTip;
    changedData.mar_egysegarme = this.selectedAlvTipName;

  }
  if (this.newMegjegyzes && this.newMegjegyzes !== this.megrendeles.megjegyzes) {
    changedData.megjegyzes = this.newMegjegyzes;
  }
  if (this.newBelsoHivatkozas && this.newBelsoHivatkozas !== this.megrendeles.belsoHivatkozas) {
    changedData.belsoHivatkozas = this.newBelsoHivatkozas;
  }

  // Überprüfe, ob es Änderungen gibt
  if (Object.keys(changedData).length === 0) {
    alert('Nem történt módosítás.');
    return;
  }

  // Erstelle die Post-Daten
  const postData = {
    az: this.megrendeles.az,
    data: changedData,
  };

  try {
    console.log('Zu speichernde Daten:', postData);
    const response = await axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData);
    if (response.data.success) {
      //alert('mentés OK');
      this.$router.push('/osszesMegrendeles');
    } else {
      console.error('Fehler beim Speichern:', response.data);
      alert('Hiba a mentés során',response.data);
    }
  } catch (error) {
    console.error('Fehler beim Speichern:', error);
    alert('Hiba a mentös során',error);
  }
},


    cancel() {
      this.$router.push("/osszesMegrendeles");
    },
  },
};
</script>

<style scoped>

.form-group {
  margin-bottom: 0px; /* Verringert den Abstand zwischen den Zeilen */
}

.form-group.row.align-items-center {
  padding: 2px 0; /* Verringert den inneren Abstand */
}

.container-fluid {
  padding: 10px;
}
.btn-container {
  display: flex;
  justify-content: center; /* Zentriert die Buttons horizontal */
  gap: 10px; /* Fügt Abstand zwischen den Buttons hinzu */
  margin-top: 20px; /* Beibehaltung des Abstands nach oben */
}
</style>
