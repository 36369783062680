<template>
  <div>

    <!-- Navigation -->
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container-fluid mt-4">
      <!-- Linker Bereich: Auftrag Details -->
      <div class="row">
        <div class="col-md-3 order-details text-left email-table-wrapper">
          <h4>Megrendelés adatok</h4>
          <ul v-if="megrendeles">
            <li><span>Megrendelő:</span> <strong>{{ megrendeles.megrendelo }}</strong></li>
            <li><span>Felrakó:</span> <strong>{{ megrendeles.felrako }}</strong></li>
            <li><span>Felrakó hely:</span> <strong>{{ megrendeles.fhely }}</strong></li>

            <li><span>Lerakó:</span> <strong>{{ megrendeles.lerako }}</strong></li>
            <li><span>Lerakó hely:</span> <strong>{{ megrendeles.lhely }}</strong></li>
            
            <li><span>Cikk:</span> <strong>{{ megrendeles.cikk }}</strong></li>
            
            <li><span>Dátum:</span> <strong>{{ megrendeles.m_datumtol }}</strong></li>
            <li><span>Szállítási határidő:</span> <strong>{{ megrendeles.szall_hatarido }}</strong></li>

            
            <li><span>Súly:</span> <strong>{{ megrendeles.m_suly }}</strong></li>
            <li><span>KM:</span> <strong>{{ megrendeles.m_km }}</strong></li>
            
            <li><span>Hivatkozási szám:</span> <strong>{{ megrendeles.hivatkozasi_szam }}</strong></li>
            <li>
              <span>GMP: </span>
              <input type="checkbox" disabled v-model="gmpCheckbox">
              <span v-if="gmpCheckbox"> igen</span>
              <span v-else> nem</span>
            </li>

              
            <li><span>Megrendelői ár:</span> <strong>{{ megrendeles.m_aregysegar }}</strong></li>
            <li><span>Ártípus:</span> <strong>{{ megrendeles.m_egysegarme }}</strong></li>

            <li><span>Alapért.alv díj:</span> <strong>{{ megrendeles.mar_egysegar }}</strong></li>
            <li><span>Alv ártipus:</span> <strong>{{ megrendeles.mar_egysegarme }}</strong></li>
            
            <li><span>Megjegyzés:</span> <strong>{{ megrendeles.megjegyzes }}</strong></li>
            
            <li><span>Fuvarszervező:</span> <strong>{{ megrendeles.fuvarszervezo }}</strong></li>
            <li><span>Készítette:</span> <strong>{{ megrendeles.felh }}  ({{megrendeles.made  }})</strong></li>
            <li><span>Utolsó módosítás:</span> <strong>{{ megrendeles.mod_felh }}  ({{ megrendeles.timest }})</strong></li>
            <li><span>Csoport:</span> <strong>{{ megrendeles.csoport }}</strong></li>
            <li><span>FTR AZ:</span> <strong>{{ megrendeles.az }}</strong></li>
            <li><span>VIR AZ:</span> <strong>{{ megrendeles.m_az }}</strong></li>
            <li><span>Belső hivatkozási szám:</span> <strong>{{ megrendeles.belsoHivatkozas }}</strong></li>
            
          </ul>
          <p v-else>Adattöltés folyamatban...</p> <!-- Anzeige eines Ladezustands -->
        </div>

        <!-- Rechter Bereich: Buttons für Aktionen -->
        <div class="col-md-2 action-buttons">

          <button 
            class="btn btn-lg btn-vissza mb-2" 
            @click="goBack"
          >
            ← Vissza
          </button>


          <button class="btn btn-primary btn-block mb-2" @click="editOrder">Megrendelés szerkesztése</button>
             <!-- Megrendelés kész Button (Deaktiviert) -->
              <button 
                class="btn btn-success btn-block mb-2" 
                @click="markAsComplete" 
                :disabled="true"
              >
              Megrendelés kész
            </button>

            <button class="btn btn-info btn-block mb-2" @click="toggleDateField">Dátum módosítása</button>
            <button class="btn btn-warning btn-block mb-2" @click="toggleCopyField">Megrendelés másolása</button>

            <!-- Megrendelés törlése Button () -->
            <button 
              class="btn btn-danger btn-block mb-2" 
              @click="confirmDeleteOrder" >
              Megrendelés törlése
            </button>

            <!-- Eingabefeld für Datum -->
            <div v-if="showDateField" class="mt-3">
              <label for="newDate">Új dátum:</label>
              <input 
                id="newDate" 
                type="date" 
                v-model="newDate" 
                class="form-control mb-2" 
              />
              <button class="btn btn-primary btn-block" @click="startDateUpdate">Start</button>
            </div>

            <!-- Eingabefeld für Megrendelés másolása -->
            <div v-if="showCopyField" class="mt-3">
                <label for="copyDate">Új dátum:</label>
                <input 
                  id="copyDate" 
                  type="date" 
                  v-model="copyDate" 
                  class="form-control mb-2" 
                />
                <label for="copyWeight">Új mennyiség:</label>
              <input 
                id="copyWeight" 
                type="number" 
                v-model="m_suly_new" 
                class="form-control mb-2" 
              />
              <button class="btn btn-primary btn-block" @click="startCopyOrder">Start</button>
            </div>

          <!-- Neuer Button -->
          <button 
            class="btn btn-secondary btn-block mb-2" 
            @click="navigateToOrderList"
          >
            Hivatkozási szám áttekintése
          </button>
        </div>



        <!-- Rechte Spalte: E-Mails -->
        <div class="col-md-7 ">
          <!-- Zugeordnete E-Mails -->
          <div class="emails-section mb-4">
            <h5>Csatolt email</h5>
            <div class="table-responsive smaller-table email-table-wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th>Dátum</th>
                    <th>Küldő</th>
                    <th>Tárgy</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="email in assignedEmails" :key="email.id" @click="openEmailModal(email)">
                    <td>{{ email.receivedDateTime }}</td>
                    <td>{{ email.from_name }} ({{ email.from_email }})</td>
                    <td>{{ email.subject }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Kunden-E-Mails -->
          <div class="emails-section">
            <h5>Email lista</h5>
            <div class="table-responsive larger-table email-table-wrapper">
              <table class="table email-table">
                <thead>
                  <tr>
                    <th>Dátum</th>
                    <th>Küldő</th>
                    <th>Tárgy</th>
                    <th>Csatolás</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="email in customerEmails" :key="email.id" @click="openEmailModal(email)">
                    <td>{{ email.receivedDateTime }}</td>
                    <td>{{ email.from_name }} ({{ email.from_email }})</td>
                    <td>{{ email.subject }}</td>
                    <td>
                      <button class="btn btn-sm btn-success"  @click.stop="assignEmailToOrder(email.az)">
                        <i class="fa fa-link"></i> 
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      

            <!-- Modal für die E-Mail-Anzeige -->
            <div v-if="showEmailModal" class="email-modal">
              <div class="email-modal-content">
                <!-- X Button innerhalb des Fensters -->
                <button class="close-button-inside" @click="closeEmailModal">
                  &times;
                </button>
                <h5>Tárgy: {{ selectedEmail.subject }}</h5>
                <p><strong>Küldő:</strong> {{ selectedEmail.from_name }} ({{ selectedEmail.from_email }})</p>
                <p><strong>Címzett:</strong> {{ selectedEmail.to_email }}</p>
                <p><strong>CC:</strong> {{ selectedEmail.cc_email }}</p>
                <p><strong>Dátum:</strong> {{ selectedEmail.receivedDateTime }}</p>
                <hr />
                <div v-html="selectedEmail.bodyHtml || selectedEmail.bodyPlainText"></div>
              </div>
            </div>


      

      
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      megrendeles: null,
      
      showEmailModal: false,
      selectedEmail: {}, // Die aktuell ausgewählte E-Mail

      assignedEmails: [], // E-Mails, die der Order zugeordnet sind
      customerEmails: [], // E-Mails, die zum Kunden gehören
      emailsWithModal: [], // E-Mails mit Modal-Status

      showDateField: false, // Steuerung für Datumfeld
      showCopyField: false, // Für Megrendelés másolása
      newDate: '',          // Neues Datum
      copyDate: '',          // Datum für Megrendelés másolása
      m_suly_new: 0 // Neues Feld für das Gewicht
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    ...mapGetters(["getUserData"]),
    az() {
      return this.getorderData?.az || null;
    },
    napifo_az() {
      return this.getorderData?.napifo_az || null;
    },
    
    gmpCheckbox() {
      console.log('gmp',this.megrendeles.gmp);
    return this.megrendeles?.gmp == -1;  // true für Ja (-1), false für Nein (0)
  }, 
  fsz() {
        return this.getUserData ? this.getUserData.fsz : null;
      }, 
  },
  async mounted() {
    this.fetchOrderDetails();
    this.updateLogParams();
    this.fetchEmailsByOrder();
    
  },
  methods: {
    ...mapMutations(['setLogParams']),
  

    async assignEmailToOrder(emailId) {//******************************************************************************* */
    const postData = {
      order_az: this.az, // Order ID
      email_az: emailId, // E-Mail ID
      fsz: this.fsz, // User
      ceg_az:  this.getUserData.ceg_az // Unternehmens-ID
    };

    try {

      console.log('assignEmailToOrder',postData);

      const response = await axiosInstance.post('/emails/set_email_order.php', postData);
      
      if (response.status === 200 && response.data && response.data.id > 0) {
      console.log('E-Mail erfolgreich zugeordnet. ID:', response.data.id);
      //alert('E-Mail erfolgreich zugeordnet!');
      // Optional: Aktualisieren Sie die Liste der zugeordneten E-Mails
      this.fetchEmailsByOrder();
    } else {
      console.error('Fehler beim Zuordnen der E-Mail:', response.data);
      alert('Hiba az email hozzárendelésnél, kérem értesítse a programozót. ', postData);
    }
    } catch (error) {
      console.error('Fehler beim Zuordnen der E-Mail:', error);
      alert('Hiba az email hozzárendelésnél, kérem értesítse a programozót. ', postData, error);
    }
  },


  openEmailModal(email) {//******************************************************************************* */
    console.log("Email angeklickt:", email);
    this.selectedEmail = email; // Setzt die ausgewählte E-Mail
    this.showEmailModal = true; // Zeigt das Modal an
  },
  closeEmailModal() {
    this.showEmailModal = false; // Versteckt das Modal
    this.selectedEmail = {}; // Leert die ausgewählte E-Mail
  },

    updateLogParams() {//********************************************************************** */
    const logParams = {
      user: '',  // oder fsz, je nach Logik
      table_name: 'ftr_megrendeles',
      record_id: this.napifo_az
    };
    this.$store.commit('setLogParams', logParams);
  },
  goBack() {//******************************************************************************* */
        this.$router.back(); // Geht zur letzten Seite zurück
      },

  editOrder() {//******************************************************************************* */
    this.$router.push({ name: 'editOrder' });
  },
  navigateToOrderList() {//*************************************************************** */
    this.$router.push({ name: 'OrderList' });
  },
  toggleDateField() {//****************************************************************** */
      // Zeige oder verstecke das Datumsfeld
      this.showDateField = !this.showDateField;
    },
      // Umschalten des Datumsfelds für Megrendelés másolása
    toggleCopyField() {//***************************************************************** */
      this.showCopyField = !this.showCopyField;
      this.m_suly_new = this.megrendeles?.m_suly || 0; // Fülle das Feld mit dem aktuellen Gewicht
      this.showDateField = false; // Schließt das andere Feld
    },
    async startDateUpdate() {//****  visznylat áthelyezése másik napra ***************************************** */
      if (!this.newDate) {
        alert('Bitte ein gültiges Datum eingeben!');
        return;
      }

      // Placeholder für API-Aufruf

      const datawert={
        m_datumtol:this.newDate
      };

      const postData = {
        az: this.az,
        data: datawert
      };

      try {

        console.log('athelyezes',postData);
        const response = await axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData);

        if (response.status === 200 && response.data.success) {
          //alert('Megrendelés áthelyezése kész.');
          //this.fetchOrderDetails(); // Daten erneut laden
          //this.showDateField = false; // Feld ausblenden

          this.$store.commit('setNapiDatum', this.newDate);
          // Leite zur Seite "osszesMegrendeles" weiter
          //this.$router.push({ name: 'osszesMegrendeles'});
          this.$router.go(-1);

        } else {
          alert('Fehler beim Aktualisieren des Datums.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Datums:', error);
        alert('Hiba történt beim Aktualisieren.');
      }
    },

    async startCopyOrder() {//****************************************************************************** */
      if (!this.copyDate) {
        alert("Kérjük, adjon meg egy dátumot a másoláshoz!");
        return;
      }

      const postData = {
        az: this.az,
        datum_new: this.copyDate,
        m_suly_new: this.m_suly_new // Übergebe das neue Gewicht
      };

      try {

        console.log('masol postData',postData);

        const response = await axiosInstance.post("/megrendeles/copy_megrendeles.php", postData);
        if (response.data.success) {
         // alert("Megrendelés másolása sikeres! Új az: " + response.data.id);
          this.$store.commit('setNapiDatum', this.copyDate);
          // Leite zur Seite "osszesMegrendeles" weiter
          //this.$router.push({ name: 'osszesMegrendeles' });
          this.$router.go(-1);

        } else {
          console.error("Hiba történt a másolás során:", response.data.error);
          alert("Hiba történt a másolás során: " + response.data.error);
        }
      } catch (error) {
        console.error("API-hiba:", error);
        alert("Hiba történt a másolás során. Kérjük, próbálja újra.");
      }
    },

    async fetchOrderDetails() {//******************************************************************************** */
      const postData = {
        az: this.napifo_az
      };
      try {
        console.log('posData',postData);
        const url = "/megrendeles/egy_megrendeles.php";
        const response = await axiosInstance.post(url, postData);

        console.log(response.data);

        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          this.megrendeles = response.data.data[0];
          console.log('megrendeles', this.megrendeles);
             // Speichern der Daten im Vuex-Store
          this.$store.commit('setorderData', this.megrendeles);
        } else {
          console.log('Antwort enthält keine Daten oder ist nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
      }

      this.fetchEmailsByCustomer();

    },
    
    
    async fetchEmailsByOrder() {//******************************************************************* */
      /*  
      const postData = {
          ceg_az: this.megrendeles?.ceg_az,
          order_az: this.megrendeles?.az
        };
*/
         const postData = {
          ceg_az:  this.getUserData.ceg_az,
          order_az: this.napifo_az
        };

        try {
          console.log('postData for fetchEmailsByOrder', postData);
          const url = '/emails/get_emailOrderAZ.php';
          const response = await axiosInstance.post(url, postData);

          console.log('Response fetchEmailsByOrder', response.data);

          if (response.status === 200 && response.data.data && response.data.data.length > 0) {
            this.assignedEmails = response.data.data;
            //console.log('Assigned Emails', this.assignedEmails);
          } else {
            console.log('Keine Daten oder Antwortstatus ist nicht 200:', response);
            this.errorMessage = 'Fehler beim Abrufen der zugeordneten E-Mails: ' + response.statusText;
          }
        } catch (error) {
          console.error('Fehler beim Abrufen der zugeordneten E-Mails:', error);
          this.errorMessage = 'Fehler beim Abrufen der zugeordneten E-Mails: ' + error.message;
        }
      },

      async fetchEmailsByCustomer() {//************************************************************************** */
          
        
        
        const postData = {
            ceg_az:  this.getUserData.ceg_az,
            v_kod: this.megrendeles.m_megrendeloaz
          };

          try {
            console.log('postData for fetchEmailsByCustomer', postData);
            const url = '/emails/get_emailVkod.php';
            const response = await axiosInstance.post(url, postData );

            console.log('Response fetchEmailsByCustomer', response.data);

            if (response.status === 200 && response.data.data && response.data.data.length > 0) {
              this.customerEmails = response.data.data;
              console.log('Customer Emails', this.customerEmails);
            } else {
              console.log('Keine Daten oder Antwortstatus ist nicht 200:', response);
              this.errorMessage = 'Fehler beim Abrufen der Kunden-E-Mails: ' + response.statusText;
            }
          } catch (error) {
            console.error('Fehler beim Abrufen der Kunden-E-Mails:', error);
            this.errorMessage = 'Fehler beim Abrufen der Kunden-E-Mails: ' + error.message;
          }
        },


    markAsComplete() {//***************************************************************************************** */
      // Logik für das Markieren des Auftrags als abgeschlossen
    },
    modifyQuantity() {
      // Logik für das Ändern der Menge
    },
    modifyDate() {
      // Logik für das Ändern des Datums
    },
    confirmDeleteOrder() {
      if (confirm('Biztosan törölni szeretné ezt a megrendelést?')) {
          this.deleteOrder();
      }
    },
    async deleteOrder() {//****************************************************************************** */
      // Logik für das Löschen des Auftrags
      
      const datawert={
        storno:-1
      };

      const postData = {
        az: this.az,
        data: datawert
      };

      try {

          console.log('storno',postData);
        const response = await axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData);

        if (response.status === 200 && response.data.success) {
          //alert('Törlés kész ');
          this.$router.push({ name: 'osszesMegrendeles' });
        } else {
          alert('Fehler beim Aktualisieren des Datums.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Datums:', error);
        alert('Hiba történt beim Aktualisieren.');
      }


    }
  }
};
</script>

<style scoped>

.container {
  margin-top: 20px; /* Wird benutzt, kann bleiben */

}

.order-details ul {
  list-style-type: none;
  padding: 0;
}

.order-details ul li {
  margin-bottom: 5px;
  line-height: 1.2; /* Kann bleiben, da es den Zeilenabstand regelt */
  font-size: 14px;
}

/* Redundant: action-buttons und andere Klassen überschneiden sich */
.action-buttons .btn {
  width: 100%; /* Reduzierte Breite auf 100%, um besser anzupassen */
  padding: 10px;
  font-size: 16px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

input[type="checkbox"][disabled] {
  opacity: 0.8; /* Hilft, wenn Checkbox deaktiviert ist */
  border: 1px solid #ccc;
}

#newDate {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Entfernen: Diese Positionierung ist nicht mehr relevant */
.back-button {
  display: none; /* Kann entfernt werden, da es keine Anwendung mehr hat */
}

.emails-section {
  margin-bottom: 20px;
  padding-right: 15px; /* Optional */
}

.smaller-table, .larger-table {
  overflow-y: auto;
}

.smaller-table {
  max-height: 300px; /* Spezifisch für kleine Tabellen */
}

.larger-table {
  max-height: 500px; /* Spezifisch für größere Tabellen */

}

.table {
  table-layout: auto;
  width: 100%;
}

.table th,
.table td {
    padding: 4px; /* Abstand innerhalb der Zellen */
    font-size: 12px; /* Textgröße */
    line-height: 1.0; /* Zeilenhöhe */
    text-align: left; /* Linksbündig für Text */
    
}

.email-table th,
.email-table td {
    padding: 4px; /* Gleicher Abstand */
    font-size: 12px; /* Einheitliche Schriftgröße */
    line-height: 1.0; /* Einheitliche Zeilenhöhe */
    text-align: left; /* Gleiche Textausrichtung */
    
}

.email-table-wrapper {
  border: 2px solid #ccc; /* Rahmenfarbe */
  border-radius: 8px; /* Abgerundete Ecken */
  padding: 15px; /* Innenabstand */
  margin-bottom: 20px; /* Abstand zwischen den Tabellen */
  background-color: #f9f9f9; /* Hintergrundfarbe */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Schatten für mehr Tiefe */
}

.email-table-wrapper h5 {
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left; /* Text linksbündig */
  color: #333;
}

.email-table th:nth-child(1),
.email-table td:nth-child(1) {
  width: 15%; /* Dátum */
}

.email-table th:nth-child(2),
.email-table td:nth-child(2) {
  width: 30%; /* Küldő */
}

.email-table th:nth-child(3),
.email-table td:nth-child(3) {
  width: 47%; /* Tárgy */
}

.email-table th:nth-child(4),
.email-table td:nth-child(4) {
  width: 8%; /* Csatolás */
}




/* Spezielle Modal-Anpassungen */
.email-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.email-modal-content p {
  margin-bottom: 5px; /* Reduziert den Abstand zwischen Absätzen */
  line-height: 1.2; /* Verringert die Zeilenhöhe */
  font-size: 14px; /* Optional: Schriftgröße anpassen */
}

.email-modal-content h5 {
  margin-bottom: 10px; /* Reduzierter Abstand unter der Überschrift */
  font-size: 16px; /* Optional: Schriftgröße anpassen */
  line-height: 1.2; /* Verringert die Zeilenhöhe */
}

.email-modal-content div {
  line-height: 1.4; /* Reduziert die Zeilenhöhe im Body-Inhalt */
}


/* X-Button */
.close-button-inside {
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  color: #333;
}

.close-button-inside:hover {
  color: #ff0000;
}

/* Spezielles Icon */
.assign-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: green;
  transition: color 0.3s ease;
}

.assign-icon:hover {
  color: rgb(236, 10, 10);
}

/* Vissza-Button */
.btn-vissza {
  background-color: rgb(164, 245, 59);
  color: rgb(10, 10, 10);
  border: none;
}

.btn-vissza:hover {
  background-color: #478;
}


.assign-icon {
    cursor: pointer;
    font-size: 0.8rem; /* Icon-Größe */
    color: green; /* Standardfarbe */
    transition: color 0.3s ease;
}

.assign-icon:hover {
    color: rgb(236, 10, 10); /* Hover-Farbe */
}


</style>
