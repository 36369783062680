<template>
    <div>
      <navigation-bar></navigation-bar>
      <div class="container-fluid">
        <!-- Woche Navigation -->
        <div class="d-flex justify-content-between align-items-center mb-3">
          <button class="btn btn-primary" @click="previousWeek">Előző hét</button>
          <h3>{{ weekDisplay }}</h3>

          <div class="d-flex align-items-center">
          <select class="form-select me-2" v-model="selectedFsz" @change="onFszChange">
            <option v-for="fsz in fszList" :key="fsz" :value="fsz">
              {{ fsz }}
            </option>
          </select>
          <button class="btn btn-outline-primary btn-sm" @click="goToWeeklyOrders">
            <i class="bi bi-search" style="font-size: 0.8rem;"></i>Megrendelések
          </button>

          <button class="btn btn-outline-success btn-sm" @click="goToWeeklyTrucks">
            <i class="bi bi-truck" style="font-size: 0.8rem;"></i> Fuvarok
          </button>

        </div>

          
          <button class="btn btn-primary" @click="nextWeek">Következő hét</button>
        </div>
  
        <!-- Tabellen für jeden Tag -->
        <div class="row flex-nowrap" style="overflow-x: auto;">
          <div
            class="col-2"
            v-for="(day, index) in weekDays"
            :key="index"
            :style="{ minWidth: tableWidth + 'px' }"
          >
            <div class="text-center mb-3">
              <h5>{{ formatDate(day) }}</h5>
            </div>
            <div
              class="table-responsive"
              :style="{ maxHeight: tableHeight + 'px', overflowY: 'scroll' }"
            >
              <table class="table table-bordered">
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in dailyData[day]"
                    :key="rowIndex"
                    @mouseover="showDetails(row, $event)"
                    @mouseleave="clearDetails"
                  >
                  <td
                    :style="{ width: columnWidth + 'px', 
                    backgroundColor: getBackgroundColor(row.tipus1), 
                    color: getFontColor(row.tipus1) }"
                    @click="handleCellClick(row, 1)"
                    @contextmenu.prevent="showContextMenu(row, $event, 1)"
                    @mouseover="showAdditionalTooltip(row, $event, 1)"
                    @mouseleave="clearAdditionalTooltip"
                  >
                    {{ row.szoveg1 }}
                  </td>
                  <td
                    :style="{ width: columnWidth + 'px', 
                    backgroundColor: getBackgroundColor(row.tipus2), 
                    color: getFontColor(row.tipus2) }"
                    @click="handleCellClick(row, 2)"
                    @contextmenu.prevent="showContextMenu(row, $event, 2)"
                    @mouseover="showAdditionalTooltip(row, $event, 2)"
                    @mouseleave="clearAdditionalTooltip"
                  >
                    {{ row.szoveg2 }}
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <!-- Kontextmenü -->
        <ContextMenu
            :showMenu="showMenu"
            :menuX="menuX"
            :menuY="menuY"
            :selectedRow="selectedRow"
            :selectedSide="selectedSide"
            :fsz="fsz"
            @updateTipus="updateTipus"
            @addNewRow="addNewRow"
            @deleteRow="deleteRow"
            @updateTipusTo62="updateTipusTo62"
            @updateTipusTo63="updateTipusTo63"
            @showDatePicker="showDatePicker"
            @closeContextMenu="closeContextMenu"
          ></ContextMenu>

        <!-- Tooltip mit zusätzlichen Informationen -->
        <div 
              v-if="tooltipData" 
              class="tooltip" 
              :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
            >
              <p><strong>Viszonylat:</strong> {{ tooltipData.felrako }} - {{ tooltipData.lerako }}</p>
              <p><strong>Megrendelő:</strong> {{ tooltipData.megrendelo }}</p>
              <p><strong>Cikk:</strong> {{ tooltipData.cikk }}</p>
              <p><strong>Szállítási határidő:</strong> {{ tooltipData.szall_hatarido }}</p>
              <p><strong>Hivatkozási szám:</strong> {{ tooltipData.hivatkozasi_szam }}</p>
              <p><strong>Megr. ár:</strong> {{ tooltipData.megr_ar }} {{ tooltipData.megrar_me }}</p>
              <p><strong>Alv. ár:</strong> {{ tooltipData.alv_ar }} {{ tooltipData.alvar_me }}</p>
              <p><strong>Megjegyzés:</strong> {{ tooltipData.megjegyzes }}</p>
              <p><strong>Belső hivatkozási szám:</strong> {{ tooltipData.belsoHivatkozas }}</p>
            </div>

            <!-- Zweite Tooltip mit spezifischen Informationen für tipus 6, 61, 7, 71 -->
            <div 
              v-if="additionalTooltipData" 
              class="tooltip" 
              :style="{ top: additionalTooltipY + 'px', left: additionalTooltipX + 'px' }"
            >
              <p><strong>Fuvarozó:</strong> {{ additionalTooltipData.fuvarozo }}</p>
              <p><strong>Kisár:</strong> {{ additionalTooltipData.kisar }}</p>
            </div>




    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axiosInstance from "@/axiosInstance";
  import { mapMutations } from 'vuex'; // Dies ist der richtige Platz für den Import
  import ContextMenu from '@/components/ContextMenu.vue';
  
  export default {
    data() {
      return {
        currentWeek: this.getWeek(new Date()),
    currentYear: new Date().getFullYear(),
    weekDays: [],
    dailyData: {}, // Daten pro Tag
    tableWidth: 200,
    tableHeight: 700,
    columnWidth: 70,
    showMenu: false,
    menuX: 0,
    menuY: 0,
    selectedRow: null,
    selectedSide: null,

    selectedFsz: null, // Aktuell ausgewählter "fsz"
    fszList: [], // Liste der verfügbaren "fsz"-Optionen

      tooltipData: null,    // Daten, die im Tooltip angezeigt werden
      tooltipX: 0,
      tooltipY: 0,
      additionalTooltipData: null, // Daten für die zweite Tooltip
      additionalTooltipX: 0,
      additionalTooltipY: 0
      };
    },
    components: {
    ContextMenu,
    
  },
    computed: {
      ...mapGetters(["getUserData"]),
      fsz() {
        return this.getUserData ? this.getUserData.fsz : null;
      },
      weekDisplay() {
        return `${this.currentYear} - ${this.currentWeek}. hét`;
      },
    },
    methods: {
      ...mapMutations(['setorderData']), // Nutze mapMutations, um die Mutation in die Komponente zu integrieren
      ...mapMutations(['setLogParams']),

      goToWeeklyOrders() {
        this.$router.push({ name: "WeeklyOrders" });
      },

      goToWeeklyTrucks() {
        this.$router.push({ name: "WeeklyTrucks" });
      },

      async loadFszList() {
            try {
              const { ceg_az, currentYear, currentWeek } = this.$store.getters.getWochenAuswahl;

              const postData = {
                cegaz: ceg_az,
                ev: currentYear,
                het: currentWeek,
              };

              const previousSelectedFsz = this.selectedFsz; // Speichere die aktuelle Auswahl

              const response = await axiosInstance.post("/drive/list_fszHet.php", postData);

              if (response.status === 200 && response.data) {
                this.fszList = response.data.data.map(item => item.fsz);

                // Beibehalten der vorherigen Auswahl, falls möglich
                if (this.fszList.includes(previousSelectedFsz)) {
                  this.selectedFsz = previousSelectedFsz;
                } else {
                  this.selectedFsz = this.getUserData.fsz; // Fallback auf Standardwert
                }

                console.log("fszList loaded:", this.fszList);
              } else {
                console.error("Fehler beim Abrufen der 'fsz'-Liste:", response);
              }
            } catch (error) {
              console.error("API-Fehler beim Laden der 'fsz'-Liste:", error);
            }
          },


          onFszChange() {

                    const wochenAuswahl = {
              ...this.$store.getters.getWochenAuswahl,
              fsz: this.selectedFsz, // Speichere die Auswahl des Fuvarszervező
            };

            // Speichere die aktualisierte Auswahl in Vuex
            this.$store.dispatch("saveWochenAuswahl", wochenAuswahl);


                // Wenn sich der ausgewählte "fsz" ändert, Daten neu laden
                this.fetchWeekData();
          },

          showAdditionalTooltip(row, event, side) {//*********************************************************** */
              if ([6, 61, 7, 71].includes(parseInt(side == 1 ? row.tipus1 : row.tipus2))) {
                this.additionalTooltipData = {
                  fuvarozo: side == 1 ? row.fuvarozo1 : row.fuvarozo2,
                  kisar: side == 1 ? row.kisar1 : row.kisar2
                };
                this.additionalTooltipX = event.clientX + 10;
                this.additionalTooltipY = event.clientY + 10;
              }
            },



        clearAdditionalTooltip() {//************************************************************************** */
          this.additionalTooltipData = null;
        },

        showDetails(row, event) {  //************************************************************ */
      // Tooltip nur anzeigen, wenn tipus1 und tipus2 beide 0 sind
      if (row.tipus1 === '0' && row.tipus2 === '0') {
        this.tooltipData = {
          felrako:row.szoveg1,
          lerako: row.szoveg2,
          megrendelo: row.megrendelo,
          cikk: row.cikk,
          szall_hatarido: row.szall_hatarido,
          hivatkozasi_szam: row.hivatkozasi_szam,
          megr_ar: row.m_egysegar,
          megrar_me:row.m_egysegarme,
          alv_ar: row.mar_egysegar,
          alvar_me:row.mar_egysegarme,
          megjegyzes:row.megjegyzes,
          belsoHivatkozas:row.belsoHivatkozas
        };
        this.updateTooltipPosition(event);
        document.addEventListener('mousemove', this.updateTooltipPosition);
      }
    },
    clearDetails() {//******************************************************************************** */
      ////console.log("Mouseleave, Tooltip wird entfernt");
      this.tooltipData = null;
      document.removeEventListener('mousemove', this.updateTooltipPosition);
    },
    updateTooltipPosition(event) {//******************************************************************* */
      this.tooltipX = event.clientX + 10;
      this.tooltipY = event.clientY + 10;
    //  ////console.log(`Tooltip Position: X=${this.tooltipX}, Y=${this.tooltipY}`);
    },

              showContextMenu(row, event, side) {
            // Speichere die Position des Kontextmenüs
            this.menuX = event.clientX;
            this.menuY = event.clientY;
            
            // Speichere die Zeile und die Seite (1 für tipus1, 2 für tipus2)
            this.selectedRow = row;
            this.selectedSide = side;
            
            // Zeige das Menü an
            this.showMenu = true;
            
            // Verstecke das Menü, wenn irgendwo anders geklickt wird
            document.addEventListener('click', this.closeContextMenu);
          },

        
          handleCellClick(row, side) { //*********************************************************************** */
      // Überprüfen, ob die Bedingungen für tipus1 und tipus2 erfüllt sind
      if ((side === 1 && row.tipus1 ==0 ) || (side === 2 && row.tipus2 == 0)) {
      // Erstelle ein Objekt mit den relevanten Daten
            const orderData = {
              az: row.az,
              cegaz: row.cegaz,
              datum: row.datum,
              viszonylat: row.viszonylat,
              napifo_az: row.napifo_az,
              napimellek_az: side === 1 ? row.napimellek_az1 : row.napimellek_az2,
              tipus: side === 1 ? row.tipus1 : row.tipus2,
              megrendelo: row.megrendelo,
              cikk: row.cikk,
              szall_hatarido: row.szall_hatarido,
              hivatkozasi_szam: row.hivatkozasi_szam,
              oldal: side,
              csoport:row.csoport,
              fsz:row.fsz,
              gmp:row.gmp
            };

            // Speichere die Daten im Store
            this.setorderData(orderData);

      // Navigiere zur neuen OrderDetails-Seite
      this.$router.push({ name: 'orderDetails' });

      } else if ((side === 1 && (row.tipus1 == 5 || row.tipus1 == 51)) || (side === 2 && (row.tipus2 == 5 || row.tipus2 == 51 ))) {
        // Erstelle ein Objekt mit den relevanten Daten
        const orderData = {
          az: row.az,
          cegaz: row.cegaz,
          datum: row.datum,
          viszonylat: row.viszonylat, // Unterschied je nach Seite
          napifo_az: row.napifo_az,
          napimellek_az: side === 1 ? row.napimellek_az1 : row.napimellek_az2,
          tipus: side === 1 ? row.tipus1 : row.tipus2,
          megrendelo: row.megrendelo,
          cikk: row.cikk,
          szall_hatarido: row.szall_hatarido,
          hivatkozasi_szam: row.hivatkozasi_szam,
          oldal: side,
          fo_megjegyzes:row.megjegyzes,
          csoport: row.csoport,
          fsz:row.fsz,
          gmp:row.gmp
        };

        // Speichere die Daten im Store
        this.setorderData(orderData);

        // Leere die Truck-Daten im Store
        this.$store.commit('clearTruckData');

        // Navigiere zur newTruck Seite
        this.$router.push({ name: 'newTruck' });


      }else  if ((side === 1 && (row.tipus1 == 6 || row.tipus1 == 61)) || (side === 2 && (row.tipus2 == 6 || row.tipus2 == 61))) {
      
          // Lade die Truck-Daten basierend auf `ceg_az` und `napimellek_az`
          const napimellekAz = side === 1 ? row.napimellek_az1 : row.napimellek_az2;
          const cegaz = row.cegaz;

          ////console.log('cegaz', cegaz);
          ////console.log('napimellek_az', napimellekAz);

          // Wenn `napimellekAz` vorhanden ist, bedeutet es, dass es eine existierende Lieferung ist
          if (napimellekAz) {
            this.loadTruckData(cegaz, napimellekAz, true); // true für Bearbeitungsmodus
          } else {
            this.$router.push({ name: 'newTruck' }); // Leite zur neuen Lieferung weiter
          }
      
     } else {
        // Bedingung nicht erfüllt, optional: Zeige eine Nachricht oder mache nichts
        ////console.log('Bedingung nicht erfüllt: tipus1 oder tipus2 ist nicht 5');
      }
    },
    async loadTruckData(cegaz, napimellekAz) {//*************************************************************************************** */
      try {
        const postData = {
          ceg_az: cegaz,
          napimellek_az: napimellekAz
        };

        // API-Aufruf zum Laden der Truck-Daten mit `ceg_az` und `napimellek_az`
        const response = await axiosInstance.post('/napi_mellek/list_truck.php', postData);

        // Überprüfen, ob die Antwort erfolgreich ist und Daten vorhanden sind
        if (response.data && response.data.data) {
          ////console.log('Truck-Daten empfangen:', response.data.data[0]);

          // Committe die geladenen Truck-Daten in den Vuex-Store
          this.$store.commit('setTruckData', response.data.data[0]);

          // Navigiere zur entsprechenden Seite: `editTruck` für Bearbeitung oder `newTruck` für neue Lieferungen
          const targetPage =  'editTruck';
          this.$router.push({ name: targetPage });
        } else {
          ////console.error('Fehler beim Laden der Truck-Daten.');
        }
      } catch (error) {
        ////console.error('Fehler beim API-Aufruf:', error);
      }
    },

      async fetchWeekData() {//************************************************************************************ */
        try {
          const { ceg_az,  currentYear, currentWeek } = this.$store.getters.getWochenAuswahl;

          const fsz = this.selectedFsz || this.getUserData.fsz;

          const postData = {
            ceg_az:ceg_az,
            fsz:fsz,
            ev: currentYear,
            het: currentWeek,
          };
  
          // Speichere postData in den Vuex-Store
          this.$store.dispatch('saveWeekPostData', postData);


          console.log('fetchWeekData postData',postData);
          const response = await axiosInstance.post("/drive/list_driveFSZ.php", postData);
  
          if (response.status === 200 && response.data) {
            console.log(response.data);
            this.processWeekData(response.data.data);
          } else {
            console.error("Fehler beim Abrufen der Wochendaten:", response);
          }
        } catch (error) {
          console.error("API-Fehler:", error);
        }
      },
      components: {
        ContextMenu
      },
      processWeekData(data) {//**************************************************************************** */
        this.dailyData = {};
        for (let day of this.weekDays) {
          this.dailyData[day] = data.filter(
            (item) => new Date(item.datum).toISOString().split("T")[0] === day
          );
        }
      },


      getMondayOfWeek(year, week) {
  const jan4 = new Date(year, 0, 4);
  const firstMonday = new Date(jan4.getTime() - ((jan4.getDay() + 6) % 7) * 86400000);
  const mondayOfWeek = new Date(firstMonday.getTime() + (week - 1) * 7 * 86400000);
  
  console.log(`getMondayOfWeek: Jahr: ${year}, Woche: ${week}, Montag: ${mondayOfWeek}`);
  return mondayOfWeek;
},
initializeWeekDays() {

  console.log('Aktuelle Woche:', this.currentWeek, 'Aktuelles Jahr:', this.currentYear);


  const mondayOfWeek = this.getMondayOfWeek(this.currentYear, this.currentWeek);

  console.log("initializeWeekDays -> Montag der Woche:", mondayOfWeek);

  this.weekDays=[];

  this.weekDays = Array.from({ length: 7 }, (_, i) => {
       const day = new Date(mondayOfWeek);
    day.setDate(mondayOfWeek.getDate() + i+1);
       return day.toISOString().split("T")[0];
  });

  console.log("initializeWeekDays -> Aktualisierte weekDays:", this.weekDays);
},




      previousWeek() {
          let { currentWeek, currentYear, ceg_az } = this.$store.getters.getWochenAuswahl;

          currentWeek -= 1;
          if (currentWeek < 1) {
              currentWeek = 52;
              currentYear -= 1;
          }

          const wochen_auswahl = { currentWeek, currentYear, ceg_az, fsz: this.selectedFsz };
          console.log('Wochenauswahl',wochen_auswahl);
          this.$store.dispatch("saveWochenAuswahl", wochen_auswahl);

          // Aktualisiere die Wochentage und lade die Daten
          this.currentWeek = currentWeek;
          this.currentYear = currentYear;
          this.initializeWeekDays();
          this.fetchWeekData();
          this.loadFszList();
      },

      nextWeek() {
          let { currentWeek, currentYear, ceg_az  } = this.$store.getters.getWochenAuswahl;

          currentWeek += 1;
          if (currentWeek > 52) {
              currentWeek = 1;
              currentYear += 1;
          }

          const wochen_auswahl = { currentWeek, currentYear, ceg_az, fsz: this.selectedFsz};
          console.log('Wochenauswahl',wochen_auswahl);
          this.$store.dispatch("saveWochenAuswahl", wochen_auswahl);

          // Aktualisiere die Wochentage und lade die Daten
          this.currentWeek = currentWeek;
          this.currentYear = currentYear;
          this.initializeWeekDays();
          this.fetchWeekData();
          this.loadFszList();
      },
      getWeek(date) {
        const target = new Date(date.valueOf());
        const dayNumber = (target.getDay() + 6) % 7; // Montag = 0, Sonntag = 6
        target.setDate(target.getDate() - dayNumber + 3); // Auf den Donnerstag der aktuellen Woche springen
        const firstThursday = new Date(target.getFullYear(), 0, 4); // Der erste Donnerstag des Jahres
        const diff = target - firstThursday;
        return 1 + Math.round(diff / (7 * 24 * 60 * 60 * 1000)); // Anzahl der vollen Wochen
      },

      async updateTipus(row, side) { //********************************************************************************** */
    try {
        // Bestimme den aktuellen Tipus für die ausgewählte Seite (1 oder 2)
        const currentTipus = side === 1 ? row.tipus1 : row.tipus2;

        console.log('currentTipus',currentTipus);

        // Bestimme den neuen Tipus-Wert basierend auf den aktuellen Werten
        let newTipus = null;
        
        if (currentTipus === '5') {
            newTipus = '51';
        } else if (currentTipus === '51') {
            newTipus = '5';
        } else if (currentTipus === '6') {
            newTipus = '61';
        } else if (currentTipus === '61') {
            newTipus = '6';
        } else if (currentTipus === '7') {
            newTipus = '71';
        } else if (currentTipus === '71') {
            newTipus = '7';
        } else {
            // Wenn der Wert nicht zu den erwarteten gehört, abbrechen
            ////console.log('Nur Zellen mit tipus=5, 6, 7, 51, 61, oder 71 können geändert werden.');
            alert('Csak a priorizált rakodások módosíthatóak.');
            return;
        }

        const postData = {
            az: row.az, // Verwende die ID, um den richtigen Datensatz zu identifizieren
            tipusField: side === 1 ? 'tipus1' : 'tipus2', // Bestimme, welches Feld aktualisiert werden soll
            tipusValue: newTipus // Setze den neuen Tipus-Wert
        };

        const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);



        if (response.status === 200 && response.data.success) {
            this.fetchWeekData(); 
            ////console.log('Tipus erfolgreich aktualisiert in der Datenbank');
        } else {
            ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
            alert('Hiba történt az adatbázis frissítése közben.');
        }
    } catch (error) {
        ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
        alert('Hiba történt az adatbázis frissítése közben.');
    }
},
async addNewRow(napifo_az, fsz) {//********************************************************************************************* */
        try {
          const postData = {
            napifo_az: napifo_az, // Übergebe den napifo_az-Wert
            felh: fsz             // Übergebe den angemeldeten Fuvarszervező
          };

    //    ////console.log('new_row',postData);

          const response = await axiosInstance.post('/drive/new_row.php', postData); // Passe den API-Endpunkt an

          if (response.status === 200) {
         //   alert('Új sor sikeresen hozzáadva!'); // Erfolgsmeldung
            this.fetchWeekData(); // Aktualisiere die Daten nach dem Einfügen der neuen Zeile
          } else {
        //    ////console.error('Hiba történt:', response.data.message);
            alert('Hiba történt az új sor hozzáadása közben.');
          }
        } catch (error) {
      //    ////console.error('Hiba az API kérés során:', error);
          alert('API-hiba történt az új sor hozzáadása közben.');
        }
      },
      async deleteRow(row) {//**************************************************************************************** */
            try {
              // Überprüfen, ob die Bedingungen erfüllt sind

////console.log('STORNO -- row.az',row.az);
////console.log('row.tipus1',row.tipus1);
////console.log('row.tipus2',row.tipus2);
////console.log('napimellek_az1',row.napimellek_az1);
////console.log('napimellek_az2',row.napimellek_az2);


              //if (x===1) {
          if ((row.tipus1 == 5 || row.tipus1 == 7) && (row.tipus2 == 5 || row.tipus2 == 7) &&  row.napimellek_az1 == 0 && row.napimellek_az2 == 0) {              

                const postData = {
                  az: row.az // Übergebe die ID der Zeile, die gelöscht werden soll
                };
                const response = await axiosInstance.post('/drive/del_row.php', postData);

                if (response.status === 200 && response.data.success) {
           //       alert('A sor sikeresen törölve.');
                  this.fetchWeekData(); // Aktualisiere die Daten nach dem Löschen der Zeile
                } else {
                  ////console.error('Hiba történt:', response.data.message);
                  alert('Hiba történt a sor törlése közben.');
                }
              } else {
                alert('Csak az üres sorok törölhetőek.');
              }
            } catch (error) {
              ////console.error('Hiba történt az API kérés során:', error);
              alert('API-hiba történt a sor törlése közben.');
            }
          },
          async updateTipusTo62(row, side) {//****************************************************************************** */
    try {
      // tipus62=lejelentve
      // Setze den tipus auf 62 für die ausgewählte Seite (1 oder 2)
      const postData = {
        az: row.az, // Verwende die ID, um den richtigen Datensatz zu identifizieren
        tipusField: side === 1 ? 'tipus1' : 'tipus2', // Bestimme, welches Feld aktualisiert werden soll
        tipusValue: '62' // Setze den tipus-Wert auf 63
      };

      const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);

      if (response.status === 200 && response.data.success) {
        this.fetchWeekData(); 
        ////console.log('Tipus erfolgreich auf 63 aktualisiert in der Datenbank');
      } else {
        ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
        alert('Hiba történt az adatbázis frissítése közben.');
      }
    } catch (error) {
      ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
      alert('Hiba történt az adatbázis frissítése közben.');
    }
  },
  async updateTipusTo63(row, side) {//************************************************************ */
  // Ladezustand setzen   ha le van jelentve, akkor tipus=63, ha nem, akkor törli a
  this.loading = true;

  try {
    // Dynamischer Feldname für die Seite
    const tipusField = side === 1 ? 'tipus1' : 'tipus2';

    // Überprüfe den aktuellen Wert von tipus1 oder tipus2
    const currentValue = row[tipusField];
    let postData;

    if (currentValue === '62') {
      // Original-Datenstruktur beibehalten
      postData = {
        az: row.az, // Identifizierende ID
        tipusField: side === 1 ? 'tipus1' : 'tipus2',
        tipusValue: '63' // Setze den tipus-Wert auf 63
      };
    } else if (currentValue === '6' || currentValue === '61') {
      // Neue Struktur verwenden
      postData = {
        az: row.az, // Identifizierende ID
        tipusField: side === 1 ? 'szoveg1' : 'szoveg2', // Bestimme neues Feld
        tipusValue: '...' // Setze neuen Wert
      };
    } else {
      ////console.warn('Unbekannter tipus-Wert:', currentValue);
      alert('Hiba a törlés során vagy nem volt rendszám megadva.');
      return;
    }

    // Anfrage senden

    ////console.log('chgtipus',postData);
    const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);

    if (response.status === 200 && response.data.success) {
   
      // Lokale Aktualisierung der Tabelle
      /*      
      if (currentValue === '62') {
        row[tipusField] = '63';
      } else {
        row[side === 1 ? 'szoveg1' : 'szoveg2'] = '...';
      }
      */

      // Aktualisierte Daten erneut abrufen
      await this.fetchWeekData();

      ////console.log('Tipus erfolgreich aktualisiert in der Datenbank');
    } else {
      ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
      alert('Hiba történt az adatbázis frissítése közben.');
    }
  } catch (error) {
    ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
    alert('Hiba történt az adatbázis frissítése közben.');
  } finally {
    // Ladezustand zurücksetzen
    this.loading = false;
  }
},  
showDatePicker() {//**************************************************************************** */
  // Überprüfe, ob tipus1 oder tipus2 == 6 oder 61 ist, bevor das Modal angezeigt wird

    // Überprüfe, ob tipus1 oder tipus2 == 6 oder 61 ist, bevor das Modal angezeigt wird
//    ////console.log('Modal wird angezeigt++++++++:', this.showDateModal);
//    ////console.log('Selected Side:', this.selectedSide);
  
//    ////console.log('Selected Row:', this.selectedRow);


  if (
    (this.selectedSide === 1 && (parseInt(this.selectedRow.tipus1) === 6 || parseInt(this.selectedRow.tipus1) === 61)) ||
    (this.selectedSide === 2 && (parseInt(this.selectedRow.tipus2) === 6 || parseInt(this.selectedRow.tipus2) === 61))
  ) {
    this.showDateModal = true;
  //  ////console.log('true--------------');
  } else {
    alert('Csak nem lejelentett rendszámok helyezhetők át!');
  }
},
closeContextMenu() {
      this.showMenu = false;
      document.removeEventListener('click', this.closeContextMenu);
    },





    formatDate(date) {
  const daysOfWeek = ["Hétfő", "kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"];
  const dayName = daysOfWeek[(new Date(date).getDay() + 6) % 7]; // Montag als erster Tag
  const formattedDate = new Date(date).toLocaleDateString("hu-HU"); // Ungarisches Datumsformat
  return `${formattedDate} (${dayName})`;
},

      getBackgroundColor(tipus) {
        const colors = {
          0: "#55b3ff",
          1: "#e6f2ff",
          2: "#009900",
          3: "#ff0000",
          51: "#ffff00",
          61: "#ffff00",
          10: "#009933",
          62: "#009933",
          63: "#009933",
          20: "#ffc2b3",
        };
        return colors[parseInt(tipus, 10)] || "#ffffff";
      },
      getFontColor(tipus) {
        return parseInt(tipus, 10) === 63 ? "#ff0000" : "#000000";
      },

      
    },
    mounted() {
              const wochenAuswahl = this.$store.getters.getWochenAuswahl;

        if (wochenAuswahl.currentYear) {

          console.log('wochenauswahl if',wochenAuswahl );

          this.currentWeek = wochenAuswahl.currentWeek || this.getWeek(new Date());
          this.currentYear = wochenAuswahl.currentYear || new Date().getFullYear();
          this.selectedFsz = wochenAuswahl.fsz || this.getUserData.fsz; // Standardwert aus Userdaten
        } else {
          const currentDate = new Date();
          this.currentWeek = this.getWeek(currentDate);
          this.currentYear = currentDate.getFullYear();
          this.selectedFsz = this.getUserData.fsz;

          const defaultWochenAuswahl = {
            ceg_az: this.getUserData.ceg_az,
            fsz: this.selectedFsz,
            currentYear: this.currentYear,
            currentWeek: this.currentWeek,
          };

          console.log('wochenauswahl else:',defaultWochenAuswahl);

          this.$store.dispatch("saveWochenAuswahl", defaultWochenAuswahl);
        }

      this.initializeWeekDays();
      this.fetchWeekData();
      this.loadFszList(); // Lade die Liste der "fsz"-Optionen
    },
  };
  </script>
  
  <style scoped>
  
  .table-responsive {
  margin-bottom: rem;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse; /* Verhindert unnötige Abstände */
}

td, th {
  padding: 0 !important; /* Sehr kleiner Innenabstand */
  font-size: 0.65rem; /* Reduzierte Schriftgröße */
  line-height: 0.85 !important; /* Enger Zeilenabstand */
  white-space: nowrap; /* Kein automatischer Zeilenumbruch */
  overflow: hidden; /* Kein Überlaufen des Inhalts */
  text-overflow: ellipsis; /* Kürzung mit "..." bei Überlauf */
  height: 13px !important;  /* Maximale Höhe der Zellen */
  vertical-align: middle; /* Text zentriert */
  border: 1px solid #ddd; /* Geringe Abgrenzung zwischen Zellen */
}

th {
  font-size: 0.75rem; /* Überschriften minimal größer */
  font-weight: bold; /* Fett für bessere Lesbarkeit */
}

.table  tr {
  line-height: 1.0; /* Reduzierte Zeilenhöhe */
  padding: 2px 4px; /* Optional: Weniger Innenabstand */
}



@media (max-width: 768px) {
  .table tbody tr {
    line-height: 1.1; /* Noch kompakter auf kleinen Bildschirmen */
  }

  .table td, .table th {
    padding: 2px; /* Weniger Abstand auf kleinen Bildschirmen */
  }
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Erhöhe diesen Wert, falls nötig */
  visibility: visible; /* Stelle sicher, dass der Tooltip sichtbar ist */
  opacity: 1; /* Eventuell opacity auf 1 setzen, falls es auf 0 ist */
}

.tooltip p {
  margin: 0; /* Entferne den Standard-Außenabstand */
  line-height: 1.2; /* Setze einen kleineren Zeilenabstand, z. B. 1.2 */
}


.fuvarszervezo-name {
  font-weight: bold;
  padding: 2px;
  text-align: center;
  background-color: #e6f2ff;
  color: black;
}

.row.flex-nowrap {
  display: flex;
  gap: 8px; /* Kleineren Abstand zwischen Tabellen */
  overflow-x: auto; /* Horizontal scrollbar, falls nötig */
}

.col-2 {
  min-width: 150px; /* Standardbreite für Spalten */
}

.my-small-table td, .my-small-table th {
  height: 12px;
  font-size: 0.65rem;
}

.btn-secondary i {
  font-size: unset;
  margin-right: unset;
}

.btn-outline-primary {
  padding: 4px 8px; /* Vertikal 4px, horizontal 8px */
}

  </style>
  