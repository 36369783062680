<template>
  <div>
 
      <!-- Navigation Button -->
      <button 
            class="btn btn-primary btn-lg back-button" 
            @click="goBack"
          >← Vissza
        </button>


      <navigation-bar></navigation-bar>

    
    
    <div class="container-fluid mt-2">
      <h1>
          <i class="fas fa-edit"></i> Adatok módosítása
      </h1>


      <form id="orderForm" @submit.prevent="mentes">


          <!-- Rendszám -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert -->
            <label for="rendszam_alt" class="col-form-label col-sm-1 col-md-1">Rendszám</label>
            <div class="col-sm-2 col-md-2">
              <input type="text" 
              class="form-control" 
              :value="truckData.rendszam" 
              readonly
              style="font-weight: bold; color: darkgreen;"
              >
            </div>

            <!-- Neue Auswahl -->
            
            <div class="col-sm-2 col-md-2">
              <select id="rendszam_select" class="form-control"></select>
            </div>

            <!-- Warn-Label -->
            <div class="col-sm-6 col-md-6">
                <label 
                  v-show="showWarningLabel" 
                  class="form-label text-danger"
                  style="font-weight: bold;">
                  A meglévő rendszám és pótkocsi törölve lesz, ha nem adsz meg új értékeket.
                </label>
              </div>

          </div>




          <!-- Pótkocsi -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="potkocsi_alt" class="col-form-label col-sm-1 col-md-1">Pótkocsi</label>
            <div class="col-sm-2 col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="potkocsi_alt" 
                :value="truckData.potkocsi" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-sm-2 col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="potkocsi_neu" 
                v-model="newPotkocsi" 
                placeholder="Új pótkocsi">
            </div>
          </div>



          <!-- Felségjel -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="felsegjel_alt" class="col-form-label col-sm-1 col-md-1">Felségjel</label>
            <div class="col-sm-2 col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="felsegjel_alt" 
                :value="truckData.felsegjel" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-sm-2 col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="felsegjel_neu" 
                v-model="newFelsegjel" 
                placeholder="Új országkód">
            </div>
          </div>



          <!-- Fuvarozó -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="fuvarozo_alt" class="col-form-label col-sm-1 col-md-1">Fuvarozó</label>
            <div class="col-sm-2 col-md-2">
              <input 
                type="text" 
                id="fuvarozo_alt"
                class="form-control" 
                :value="truckData.fuvarozo" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Auswahl -->
            <div class="col-sm-2 col-md-2">
              <select 
                id="fuvarozo_select" 
                class="form-control">
              </select>
            </div>
          </div>



          <!-- Felrakás Dátuma -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="felrakasDatum_alt" class="col-form-label col-sm-1 col-md-1">Felrakás dátuma</label>
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="felrakasDatum_alt" 
                :value="truckData.felrakas_napja" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-md-2">
              <input 
                type="date" 
                class="form-control" 
                id="felrakasDatum_neu" 
                v-model="newFelrakasDatum">
            </div>
          </div>



          <!-- Lerakás Dátuma -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="lerakasDatum_alt" class="col-form-label col-sm-1 col-md-1">Lerakás dátuma</label>
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="lerakasDatum_alt" 
                :value="truckData.lerakas_napja" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-md-2">
              <input 
                type="date" 
                class="form-control" 
                id="lerakasDatum_neu" 
                v-model="newLerakasDatum">
            </div>
          </div>



          <!-- Felvett Súly -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="felvettSuly_alt" class="col-form-label col-sm-1 col-md-1">Felvett súly</label>
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="felvettSuly_alt" 
                :value="truckData.fel_suly + ' tonna'" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-md-2">
              <input 
                type="number" 
                class="form-control" 
                id="felvettSuly_neu" 
                step="0.01" 
                v-model="newFelvettSuly" 
                placeholder="Mennyiség tonnában">
            </div>
          </div>


          <!-- Lerakott Súly -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="lerakottSuly_alt" class="col-form-label col-sm-1 col-md-1">Lerakott súly</label>
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="lerakottSuly_alt" 
                :value="truckData.le_suly + ' tonna'" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->

            <div class="col-md-2">
              <input 
                type="number" 
                class="form-control" 
                id="lerakottSuly_neu" 
                step="0.01" 
                v-model="newLerakottSuly" 
                placeholder="Mennyiség tonnában">
            </div>
          </div>



          <!-- Megjegyzés -->
          <div class="form-group row align-items-center">
            <!-- Alter Wert (readonly) -->
            <label for="megjegyzes_alt" class="col-form-label col-sm-1 col-md-1">Megjegyzés</label>
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="megjegyzes_alt" 
                :value="truckData.megjegyzes" 
                readonly 
                style="font-weight: bold; color: darkgreen;">
            </div>

            <!-- Neue Angabe -->
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                id="megjegyzes_neu" 
                v-model="newMegjegyzes" 
                placeholder="Új megjegyzés">
            </div>
          </div>


          <!-- Checkbox für "Eltérő ár" -->
          <div class="form-group row align-items-center">
            <label for="elteroAr" class="col-form-label col-sm-1 col-md-1">Eltérő ár</label>
            
            <div class="col-sm-2 col-md-2">
              <input type="checkbox"  
              :checked="truckData.eltero_ar== -1" disabled>
            </div>

          <!-- Neue Checkbox -->

            <label for="newelteroAr" class="col-form-label col-sm-1 col-md-1">Új eltérő ár</label>
            <div class="col-sm-2 col-md-2">
              <input type="checkbox" id="elteroAr" v-model="newelteroAr">
            </div>
          </div>

  


            

          <!-- Alvállalkozói díj -->
          <div class="form-group row align-items-center">
            <label for="alv_dij" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">
              Alv díj - kisár
            </label>

            <!-- Alte Alv díj (readonly) -->
            <div class="col-md-2">
              <input 
                type="number" 
                class="form-control" 
                :value="truckData.alv_egysegar" 
                step="0.01" 
                readonly 
                :style="{ color: 'darkgreen', fontWeight: 'bold' }"
                title="Régi alv díj"
              >
            </div>

            <!-- Neue Alv díj (editierbar, falls aktiv) -->
            <div class="col-md-2" v-if="newelteroAr">
              <input 
                type="number" 
                class="form-control" 
                id="alv_dij" 
                step="0.01" 
                v-model="new_alv_dij_ertek" 
                :style="{ color: 'blue', fontWeight: 'bold' }"
                title="Új alv díj"
              >
            </div>
          </div>


          <!-- Alvállalkozói ártípus -->
          <div class="form-group row align-items-center">
            <label for="artipus" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">
              Ártípus
            </label>

            <!-- Alter Ártípus (readonly) -->
            <div class="col-md-2">
              <input 
                type="text" 
                class="form-control" 
                :value="truckData.alv_egysegarme" 
                step="0.01" 
                readonly 
                :style="{ color: 'darkgreen', fontWeight: 'bold' }"
                title="Régi ártípus"
              >
            </div>

            <!-- Neuer Ártípus (Dropdown, falls aktiv) -->
            <div class="col-md-2" v-if="newelteroAr">
              <select 
                id="artipus_select" 
                class="form-control" 
                v-model="selectedArtipus"
              >
                <option v-for="tip in artipusOptions" :key="tip.id" :value="tip.id">
                  {{ tip.name }}
                </option>
              </select>
            </div>
          </div>

            
              <!-- ID.s -->
          <div class="form-group row align-items-center">
            <label for="alv_dij" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">
              Megr.AZ / Fuvar AZ
            </label>

            <div class="col-md-1">
              <input 
                type="number" 
                class="form-control" 
                :value="truckData.fo_az"  
                step="0.01" 
                readonly 
                :style="{ color: 'grey', fontWeight: 'italic' }"
                title="AZ"
              >
            </div>

            <!-- Alte Alv díj (readonly) -->
            <div class="col-md-1">
              <input 
                type="number" 
                class="form-control" 
                :value="truckData.az"  
                step="0.01" 
                readonly 
                :style="{ color: 'grey', fontWeight: 'italic' }"
                title="AZ"
              >
            </div>

            <div class="col-md-2">
              <button 
                type="button" 
                class="btn btn-primary" 
                @click="frissit"
                 title="A frissítés a táblázatos áttekintésben a rendszámot vagy a fuvarozó nevét aktualizálja hibás megjelenítésnél."
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>



            
          </div> 



            <!-- Buttons -->
            <div class="form-group btn-container">
              <button type="button" class="btn btn-danger me-5" @click="abbrechen">Mégse</button>
              <button type="submit" class="btn btn-success" >Mentés</button>
            </div>
        
      
        </form>  <!--form-->

      </div><!--container-->
  </div>  <!-- template-->
</template>

<script>
import { mapGetters } from 'vuex';
import axiosInstance from '@/axiosInstance';

import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

export default {
  data() {
    return {
      showWarningLabel: false, // Kontrolliert die Sichtbarkeit des Warn-Labels
      selectedRendszam: null,
      selectedRendszamMegn: null,
      newPotkocsi: null, // Für die neue Eingabe
      newFelsegjel: 'H', // Für die neue Eingabe
      selectedFuvarozo: null,
      selectedf_nev:null,
      newFelrakasDatum: null, // Speichert das neue Datum
      newLerakasDatum: null, // Speichert das neue Datum für "Lerakás"
      newFelvettSuly: null, // Speichert den neuen Wert für "Felvett Súly"
      newLerakottSuly: null, // Speichert den
      newMegjegyzes: null, // Speichert die neue Bemerkung
      newelteroAr:false,
      selectedArtipus:null,
      selectedArtipusName:null,
      new_alv_dij_ertek:null,



      fuvarozoFilter: '',
      selectedFuvarozoNev: '',
      selectedFuvarozoVaros: '',
      felrakasDatum: '',
      lerakasDatum: '',
      felvettSuly: null,
      lerakottSuly: null,
      rakodasiSzam: '',
      billenesiKod: '',
      megjegyzes:'',
      elteroAr: false, // Standardwert für die Checkbox
     
      alv_dij_ertek: 0, // Wert für den Alvállalkozói díj
     
      alvTipOptions: [], // Optionen für Alvállalkozói ártípus
      selectedAlvTipName: '', // Ausgewählter Name für Alvállalkozói ártípus
      selectedAlvTip: '' // ID des ausgewählten Ártípus
      // rendszamFilter: '',
     // rendszamOptions: [],
    // selectedPotkocsi: '',
     // selectedFelsegjel: 'H',
     //fuvarozoOptions: [],

    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    ...mapGetters(['getUserData']),
    ...mapGetters(['getTruckData']),
    orderData() {
      return this.getorderData || {};
    },
    az() {
      return this.orderData?.az || 'Standard AZ';
    },
    cegaz() {
      return this.orderData?.cegaz || 'Standard Cegaz';
    },
    datum() {
      return this.orderData?.datum || 'Standard Datum';
    },
    viszonylat() {
      return this.orderData?.viszonylat || 'Standard Viszonylat';
    },
    napifo_az() {
      return this.orderData?.napifo_az || '0';
    },
    napimellek_az() {
      return this.orderData?.napimellek_az || '0';
    },
    tipus() {
      return this.orderData?.tipus || 'Standard Tipus';
    },
    megrendelo() {
      return this.orderData?.megrendelo || 'nem ismert';
    },
    cikk() {
      return this.orderData?.cikk || 'nincs megadva';
    },
    szall_hatarido() {
      return this.orderData?.szall_hatarido || 'hiányzik';
    },
    hivatkozasi_szam() {
      return this.orderData?.hivatkozasi_szam || '-';
    },
    fo_megjegyzes() {
      return this.orderData?.fo_megjegyzes || '-';
    },
    oldal() {
      return this.orderData?.oldal || 'Standard Oldal';
    },
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    },
    ceg_az() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    },
    truckData() {
      return this.getTruckData || this.createNewTruckData();
    }
    
  },
  methods: {
    
    goBack() {
        this.$router.back(); // Geht zur letzten Seite zurück
      },

        async fetchAlvArTipus() {//************************************************************************** */
      const alvTip_megn_filter = document.getElementById('alvTip_filter').value;
      const postData = {
        tip_cegaz: 1,
        tip_megn_filter: alvTip_megn_filter
      };

      try {
        let url = "/tip/list_tip.php";
        const response = await axiosInstance.post(url, postData);
        if (response.status === 200) {
          this.alvTipOptions = response.data.data;
        } else {
          console.error('Fehler beim Laden der Daten:', response.data.message);
        }
      } catch (error) {
        console.error('Fehler bei der Anfrage:', error);
      }
    },

    setStandardValueAlvTip(standardIndex) {//*************************************************************** */
      this.alvTipOptions = [];
      let standardValue;

      switch (standardIndex) {
        case 1:
          standardValue = {
            az: '2',
            tip_az: '1',
            tip_megn: 'Fuvar',
            tip_egyseg: 'Ft/to',
            tip_cegaz: '1'
          };
          this.selectedAlvTip = standardValue.tip_az;
          this.selectedAlvTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`;
          break;
        case 2:
          standardValue = {
            az: '4',
            tip_az: '3',
            tip_megn: 'Fuvar',
            tip_egyseg: '€/to',
            tip_cegaz: '1'
          };
          this.selectedAlvTip = standardValue.tip_az;
          this.selectedAlvTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`;
          break;
        default:
          standardValue = null;
      }

      if (standardValue) {
        this.alvTipOptions.push(standardValue);
      }
    },

    selectAlvTip(itemalv) {
      this.selectedAlvTip = itemalv.tip_az;
      this.selectedAlvTipName = `${itemalv.tip_megn} - ${itemalv.tip_egyseg}`;
    },


    async fetchRendszam() {//*********************************************************************************** */

      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_rendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },

  // Optional: Methode, um eigene (Saját) Rendszam abzurufen
  async fetchSajatRendszam() {//******************************************************************************** */
      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_sajatrendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },
  selectRendszam(item) {//************************************************************************************* */
  this.selectedRendszam = item.az;
  this.selectedRendszamMegn = item.mr_rendszam;
  this.truckData.rendszam = item.mr_rendszam; // Synchronisieren mit truckData
  console.log('Ausgewählter Rendszam:', item.mr_rendszam);

  
      // Überprüfen, ob item.potkocsi existiert und nicht leer ist
      if (item.potkocsi && item.potkocsi !== '') {
        this.selectedPotkocsi = item.potkocsi;
        this.truckData.potkocsi = item.potkocsi; // Aktualisiere auch truckData.potkocsi
      } else {
        // Wenn kein potkocsi vorhanden ist, setze es auf einen Standardwert oder leere es
        this.truckData.potkocsi = '';
      }

},

    createNewTruckData() {//********************************************************************************* */
      return {
        az: null,
        fo_az: null,
        rendsz_az: null,
        rendszam: '',
        potkocsi: '',
        felsegjel: 'H',
        fuvarozo: '',
        felrakas_napja: null,
        lerakas_napja: null,
        fel_suly: null,
        le_suly: null,
        rakodasi_szam: '',
        billenes_kod: '',
        megjegyzes: '',
        datum: this.todayDate,
        status: null,
        storno: null,
        fordulo: 1,
        ceg_az: null,
        made: '',
        felh: '',
        timest: '',
        mod_felh: '',
        sajat: null,
        sorrend: null,
        ev: null,
        ho: null,
        het: null,
        het_napja: null,
        m_tipmenny: null,
        alv_artipaz:null,
        alv_egysegar:null,
        eltero_ar:null,
        alv_egysegarme:null
      };
    },
    abbrechen() {//****************************************************************************************** */
      if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
        this.$router.go(-1);
      }
    },
//************************************************************************************************************** */    
async mentes() {
  console.log('editTruck mentes start -----');

  // Erstelle ein Objekt für die geänderten Daten
  const changedData = {};

console.log('this.truckData.rendsz_az',this.truckData.rendsz_az);
console.log(' this.selectedRendszam', this.selectedRendszam);
console.log('this.truckData.rendszam',this.truckData.rendszam);
console.log('this.selectedRendszamMegn',this.selectedRendszamMegn);

  // Prüfe und füge geänderte Werte hinzu
  if (this.selectedRendszam && this.selectedRendszam !== this.truckData.rendsz_az) {
    changedData.rendsz_az = this.selectedRendszam;
  }

  if ( this.selectedRendszamMegn && this.selectedRendszamMegn !== this.truckData.rendszam) {
    changedData.rendszam = this.selectedRendszamMegn;
  }

  if (this.newPotkocsi && this.newPotkocsi !== this.truckData.potkocsi) {
    changedData.potkocsi = this.newPotkocsi;
  }

  if (this.newFelsegjel && this.newFelsegjel !== this.truckData.felsegjel) {
    changedData.felsegjel = this.newFelsegjel;
  }

  if (this.selectedf_nev && this.selectedf_nev !== this.truckData.fuvarozo) {
    changedData.fuvarozo = this.selectedf_nev;
  }

  if (this.selectedFuvarozo && this.selectedFuvarozo !== this.truckData.fuv_az) {
    changedData.fuv_az = this.selectedFuvarozo;
  }

  if (this.newFelrakasDatum && this.newFelrakasDatum !== this.truckData.felrakas_napja) {
    changedData.felrakas_napja = this.newFelrakasDatum;
  }

  if (this.newLerakasDatum && this.newLerakasDatum !== this.truckData.lerakas_napja) {
    changedData.lerakas_napja = this.newLerakasDatum;
  }

  if (this.newFelvettSuly && this.newFelvettSuly !== this.truckData.fel_suly) {
    changedData.fel_suly = this.newFelvettSuly;
  }

  if (this.newLerakottSuly && this.newLerakottSuly !== this.truckData.le_suly) {
    changedData.le_suly = this.newLerakottSuly;
  }

  if (this.newMegjegyzes && this.newMegjegyzes !== this.truckData.megjegyzes) {
    changedData.megjegyzes = this.newMegjegyzes;
  }

  if (this.newelteroAr !== undefined && this.newelteroAr !== (this.truckData.eltero_ar === -1)) {
    changedData.eltero_ar = this.newelteroAr ? -1 : 0;
  }

  if (this.selectedArtipus && this.selectedArtipus !== this.truckData.mar_artipaz) {
    changedData.alv_artipaz = parseInt(this.selectedArtipus, 10);
    changedData.alv_egysegarme = this.selectedArtipusName;
  }

  if (this.new_alv_dij_ertek && this.new_alv_dij_ertek !== this.truckData.mar_egysegar) {
    changedData.alv_egysegar = parseFloat(this.new_alv_dij_ertek);
  }




  // Überprüfe, ob es Änderungen gibt
  if (Object.keys(changedData).length === 0) {
    alert('Nem történt módosítás.');
    this.$router.go(-1);
    return;
  }

  // Erstelle die Post-Daten
  const postData = {
    az: this.truckData.az,
    fo_az: this.truckData.fo_az,
    mod_felh: this.fsz,
    data: changedData,
  };

  console.log('editTruck postData:', postData);

  try {
    // API-Aufruf mit den aktualisierten Daten
    const response = await axiosInstance.post('/napi_mellek/update_napimellek.php', postData);

    if (response.status === 200 && response.data.success) {
      console.log('Daten erfolgreich aktualisiert:', response.data);
   //   alert('mentes ok')
      this.$router.go(-1); // Zurück zur vorherigen Seite navigieren
    } else {
      console.error('Fehler beim Aktualisieren der Daten:', response.data);
      alert('Hiba a módosítás során: ' + (response.data.message || 'Ismeretlen hiba.'));
    }
  } catch (error) {
    if (error.response) {
      console.error('Fehlerstatus:', error.response.status);
      console.error('Fehlerdaten:', error.response.data);
      alert('Hiba a mentés során: ' + (error.response.data.message || 'Ismeretlen hiba.'));
    } else if (error.request) {
      console.error('Keine Antwort erhalten:', error.request);
      alert('A szerver nem válaszol.');
    } else {
      console.error('Fehlermeldung:', error.message);
      alert('Hiba: ' + error.message);
    }
  }
},

async frissit() {
  console.log('frissit start -----');

  // Erstelle ein Objekt für die geänderten Daten
  const changedData = {};


  // Prüfe und füge geänderte Werte hinzu
  if (this.selectedRendszam && this.selectedRendszam !== this.truckData.rendsz_az) {
    changedData.rendsz_az = this.selectedRendszam;
  }else{
    changedData.rendsz_az =this.truckData.rendsz_az;
  }


  if ( this.selectedRendszamMegn && this.selectedRendszamMegn !== this.truckData.rendszam) {
    changedData.rendszam = this.selectedRendszamMegn;
  }else{
    changedData.rendszam =this.truckData.rendszam;
  }

   

  if (this.selectedf_nev && this.selectedf_nev !== this.truckData.fuvarozo) {
    changedData.fuvarozo = this.selectedf_nev;
  }else{
    changedData.fuvarozo =this.truckData.fuvarozo;
  }

  if (this.selectedFuvarozo && this.selectedFuvarozo !== this.truckData.fuv_az) {
    changedData.fuv_az = this.selectedFuvarozo;
  }else{
    changedData.fuv_az =this.truckData.fuv_az;
  }
 


  // Überprüfe, ob es Änderungen gibt
  if (Object.keys(changedData).length === 0) {
    alert('Nem történt módosítás.');
    this.$router.go(-1);
    return;
  }

  // Erstelle die Post-Daten
  const postData = {
    az: this.truckData.az,
    fo_az: this.truckData.fo_az,
    mod_felh: this.fsz,
    data: changedData,
  };

  console.log('editTruck postData:', postData);

  try {
    // API-Aufruf mit den aktualisierten Daten
    const response = await axiosInstance.post('/napi_mellek/update_napimellek.php', postData);

    if (response.status === 200 && response.data.success) {
      console.log('Daten erfolgreich aktualisiert:', response.data);
   //   alert('mentes ok')
      this.$router.go(-1); // Zurück zur vorherigen Seite navigieren
    } else {
      console.error('Fehler beim Aktualisieren der Daten:', response.data);
      alert('Hiba a módosítás során: ' + (response.data.message || 'Ismeretlen hiba.'));
    }
  } catch (error) {
    if (error.response) {
      console.error('Fehlerstatus:', error.response.status);
      console.error('Fehlerdaten:', error.response.data);
      alert('Hiba a mentés során: ' + (error.response.data.message || 'Ismeretlen hiba.'));
    } else if (error.request) {
      console.error('Keine Antwort erhalten:', error.request);
      alert('A szerver nem válaszol.');
    } else {
      console.error('Fehlermeldung:', error.message);
      alert('Hiba: ' + error.message);
    }
  }
},



    //****************************************************************************************** */
  
    initSelect2Rendszam() {
  const vm = this;

  $('#rendszam_select').select2({
    placeholder: 'Új rendszám',
    allowClear: true,
    minimumInputLength: 2,
    tags: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/rendszam/list_rendszam.php',
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        return JSON.stringify({
          rendszam_filter: params.term.toUpperCase(),
        });
      },
      processResults: function (response) {
        return {
          results: response.data.map(item => ({
            id: item.mr_az,
            text: item.mr_rendszam + ' (' + item.f_nev + ')',
            rendszam:item.mr_rendszam,
            potkocsi: item.potkocsi || '',
            f_kod: item.f_kod || '',
            f_nev: item.f_nev || '',
          })),
        };
      },
      cache: true,
    },
  });

  $('#rendszam_select').on('select2:select', function (e) {
    const selectedData = e.params.data;

    // Vue-Daten aktualisieren
    vm.selectedRendszam = selectedData.id;
    vm.selectedRendszamMegn = selectedData.rendszam;
    vm.newPotkocsi = selectedData.potkocsi;
    vm.selectedFuvarozo = selectedData.f_kod;
    vm.selectedf_nev = selectedData.f_nev;

    if (vm.selectedRendszam && vm.selectedRendszamMegn === undefined) {
        vm.selectedRendszamMegn = selectedData.text; // Freitext in selectedRendszamMegn speichern
        vm.selectedRendszam = "0"; // selectedRendszam auf "0" setzen
        
    }


    // Prüfen, ob der Wert in den Fuvarozó-Optionen vorhanden ist
    if (!$('#fuvarozo_select option[value="' + selectedData.f_nev + '"]').length) {
      // Dynamisch hinzufügen, falls nicht vorhanden
      const newOption = new Option(selectedData.f_nev, selectedData.f_nev, true, true);
      $('#fuvarozo_select').append(newOption).trigger('change');
    } else {
      // Wert setzen, falls vorhanden
      $('#fuvarozo_select').val(selectedData.f_nev).trigger('change');
    }

    console.log('Rendszám:', vm.selectedRendszamMegn);
    console.log('Fuvarozó-Name:', vm.selectedFuvarozoNev);
  });

  $('#rendszam_select').on('select2:clear', function () {
    vm.selectedRendszam = null;
    vm.selectedRendszamMegn = '';
    vm.selectedPotkocsi = '';
    vm.selectedFuvarozo = '';
    vm.selectedf_nev = '';

    $('#fuvarozo_select').val('').trigger('change');
    console.log('Rendszám Auswahl wurde zurückgesetzt.');
  });
},
//************************************************************************************************ */
initSelect2Fuvarozo() {
    const vm = this;

    $('#fuvarozo_select').select2({
      placeholder: 'Új fuvarozó',
      allowClear: true,
      minimumInputLength: 2, // Mindestens 2 Zeichen für die Suche
      tags: true, // Ermöglicht die Eingabe von eigenen Werten
      ajax: {
        url: 'https://bogir.hu/V2/api/fuvarozo/list_fuvarozo.php', // API-Endpunkt für Fuvarozó
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        delay: 250, // Wartezeit für den AJAX-Call
        headers: {
          'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
        },
        data: function (params) {
          const payload = {
            fuvarozo_filter: params.term, // Suchbegriff
            f_cegaz: vm.ceg_az, // Beispiel: Benutzerbezogene Daten
          };

          console.log('Daten an den Server gesendet:', payload);
          return JSON.stringify(payload);
        },
        processResults: function (response) {
          console.log('Antwort vom Server (Fuvarozó):', response);

          if (response && response.data && Array.isArray(response.data)) {
            return {
              results: response.data.map(item => ({
                id: item.f_kod, // ID des Fuvarozó
                text: item.f_nev + ' (' + item.f_varos + ')', // Angezeigter Text
                f_nev: item.f_nev,
              })),
            };
          } else {
            console.error('Fehlerhafte API-Antwort (Fuvarozó):', response);
            return { results: [] };
          }
        },
        cache: true,
        error: function (jqXHR, textStatus, errorThrown) {
          console.error('Fehler bei der Anfrage (Fuvarozó):', textStatus, errorThrown);
        },
      },
    });

    // Event Listener für die Auswahl oder direkte Eingabe
    $('#fuvarozo_select').on('select2:select', function (e) {
      const selectedData = e.params.data;

      vm.selectedFuvarozo = selectedData.id || null; // Speichere die ID (falls vorhanden)
      vm.selectedFuvarozoNev = selectedData.text; // Speichere den Text
      vm.selectedf_nev=selectedData.f_nev;

console.log(' vm.selectedFuvarozo', vm.selectedFuvarozo);
console.log('vm.selectedf_nev',vm.selectedf_nev);

if(vm.selectedFuvarozo && vm.selectedFuvarozoNev===undefined){
  
  vm.selectedFuvarozoNev=vm.selectedFuvarozo;
  vm.selectedf_nev=vm.selectedFuvarozo;
  vm.selectedFuvarozo=0;
}


      console.log('vm.selectedRendszam for',vm.selectedRendszam);
      
      if (vm.selectedRendszam === null) {
        vm.selectedRendszam = "0";
        vm.selectedRendszamMegn='-';
        vm.newPotkocsi='-',
        vm.showWarningLabel = true;
        console.log('this.selectedRendszam back',vm.selectedRendszam);
        console.log('this.selectedRendszamMegn back',vm.selectedRendszamMegn);
      }

      
      
      console.log('Ausgewählter oder eingegebener Fuvarozó:', vm.selectedFuvarozoNev);
      console.log('rendszam_az', vm.selectedRendszam);
      console.log('rendszam', vm.selectedRendszamMegn);
    //  vm.truckData.fuvarozo = vm.selectedFuvarozoNev; // Aktualisiere truckData
    });

    // Event Listener für das Zurücksetzen
    $('#fuvarozo_select').on('select2:clear', function () {
      vm.selectedFuvarozo = null;
      vm.selectedFuvarozoNev = '';
   //   vm.truckData.fuvarozo = ''; // Aktualisiere truckData

      console.log('Fuvarozó Auswahl wurde zurückgesetzt.');
    });
  },
//************************************************************************************************* */
initArtipusSelect2() {
  const vm = this;

  $('#artipus_select').select2({
    placeholder: 'Új ártípus',
    allowClear: true,
    minimumInputLength: 1,
    ajax: {
      url: 'https://bogir.hu/V2/api/tip/list_tip.php', // API-Endpunkt für Ártípus
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term, // Suchbegriff
          ceg_az: vm.ceg_az, // Beispiel: Benutzerbezogene Daten
        };

        console.log('Daten, die an den API-Endpunkt gesendet werden:', payload);

        return JSON.stringify(payload);
      },
      processResults: function (response) {
        console.log('Antwort vom Server (Ártípus):', response);

        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tip_az, // ID der Ártípus
              text: `${item.tip_egyseg} - ${item.tip_megn}`, // Text + Einheit
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort (Ártípus):', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei der Anfrage (Ártípus):', textStatus, errorThrown);
      },
    },
  });

  // Event Listener für Auswahl
  $('#artipus_select').on('select2:select', function (e) {
    const selectedData = e.params.data;
    vm.selectedArtipus = selectedData.id; // Speichere die ID
    vm.selectedArtipusName = selectedData.text; // Speichere den Namen

    console.log('Ausgewählte Ártípus-ID:', vm.selectedArtipus);
    console.log('Ausgewählte Ártípus:', vm.selectedArtipusName);
  });

  // Event Listener für Zurücksetzen
  $('#artipus_select').on('select2:clear', function () {
    vm.selectedArtipus = null;
    vm.selectedArtipusName = '';

    console.log('Ártípus-Auswahl wurde zurückgesetzt.');
  });
},

//********************************************************************************************************* */



    
  },
  mounted() {
  
    
    if (this.truckData) {

    this.initSelect2Rendszam();
    this.initSelect2Fuvarozo();
    this.initArtipusSelect2();
    /*
    this.selectedRendszam = this.truckData.rendsz_az;
    this.selectedRendszamMegn = this.truckData.rendszam;
    this.newPotkocsi = this.truckData.potkocsi;
    this.newFelsegjel = this.truckData.felsegjel;
    this.selectedFuvarozo = this.truckData.fuv_az;
    this.selectedf_nev = this.truckData.fuvarozo;
    this.newFelrakasDatum = this.truckData.felrakas_napja || ''; // Felrakás Dátuma setzen
    this.newLerakasDatum = this.truckData.lerakas_napja || ''; // Lerakás Dátuma setzen
    this.newFelvettSuly = this.truckData.fel_suly;
    this.lerakottSuly = this.truckData.le_suly;
    this.megjegyzes = this.truckData.megjegyzes;
    this.elteroAr = this.truckData.eltero_ar == -1; // Setze elteroAr basierend auf dem Wert aus truckData
    this.alv_dij_ertek = this.truckData.alv_egysegar || 0; // Alvállalkozói díj initialisieren
    this.selectedAlvTip = this.truckData.alv_artipaz || ''; // Alvállalkozói ártípus ID initialisieren
     this.selectedAlvTipName = this.truckData.alv_egysegarme || '';

     console.log("Initial selectedAlvTipName:", this.selectedAlvTipName); // Debug-Ausgabe
*/
    //this.selectedAlvTipName = this.truckData.alv_egysegar || ''; // Name des Alvállalkozói ártípus initialisieren
    //const selectedTip = this.alvTipOptions.find(item => item.tip_az === this.selectedAlvTip);
    //    this.selectedAlvTipName = selectedTip ? `${selectedTip.tip_megn} - ${selectedTip.tip_egyseg}` : ''; // Setze Name des Alvállalkozói ártípus
  }







  },

  watch: {
    // Beobachte die Änderungen von alvTipOptions
    alvTipOptions: {
        handler() {
            // Aktualisiere den Namen des ausgewählten AlvTip
            const selectedTip = this.alvTipOptions.find(item => item.tip_az === this.selectedAlvTip);
            this.selectedAlvTipName = selectedTip ? `${selectedTip.tip_megn} - ${selectedTip.tip_egyseg}` : '';
        },
        immediate: true, // Der Watcher wird sofort beim Laden der Komponente ausgeführt
        deep: true // Beobachtet auch tiefe Änderungen innerhalb von alvTipOptions
      },

      newelteroAr(newValue) {
          if (newValue) {
            // Warte, bis das DOM-Element sichtbar ist, bevor Select2 erneut initialisiert wird
            this.$nextTick(() => {
              this.initArtipusSelect2();
            });
          }
        }


  } 

};
</script>

<style scoped>


.form-group.row.align-items-center {
    margin-bottom: 2px !important; /* Setze das Margin auf 0 und überschreibe alle anderen Regeln */
}

.small-spacing {
  margin-bottom: 2px; /* Hier kannst du den Abstand anpassen */
}

.input-group, .btn-group, .dropdown {
  display: flex;
  align-items: center; /* Stellt eine genaue vertikale Zentrierung sicher */
  height: 38px; /* Setzt eine einheitliche Höhe für alle Elemente */
}

.input-group .btn-sm {
  padding: 6px 12px; /* Passt das Padding an, um Konsistenz zu gewährleisten */
  margin-left: 5px; /* Hält den Spalt zwischen den Buttons */
  margin-right: 5px;
}

.dropdown-toggle {
  padding: 6px 12px; /* Passt das Padding an, um die Höhe mit anderen Elementen zu matchen */
  margin-left: 5px;
}

.dropdown-menu {
  max-width: 100%; /* Breite der Dropdown-Liste auf 100% der Container-Breite begrenzen */
  white-space: nowrap; /* Kein Zeilenumbruch innerhalb der Items */
  overflow: hidden; /* Überlaufenden Text ausblenden */
  text-overflow: ellipsis; /* Überlaufenden Text mit "..." anzeigen */
}

.dropdown-item {
  max-width: 100%; /* Breite der einzelnen Dropdown-Items begrenzen */
  white-space: nowrap; /* Kein Zeilenumbruch für Dropdown-Items */
  overflow: hidden; /* Überlaufenden Text ausblenden */
  text-overflow: ellipsis; /* Überlaufenden Text mit "..." anzeigen */
}

.form-group.row {
  display: flex;
  align-items: center; /* Sicherstellen, dass alles in der Reihe zentriert ist */
}

.input-group-append {
  display: flex;
  align-items: center;
}

.form-group .col-md-1, .form-group .col-md-2, .form-group .col-md-3, .form-group .col-sm-3 {
  display: flex;
  align-items: center; /* Verbessert die vertikale Ausrichtung innerhalb der Spalten */
  padding-right: 0; /* Entfernt unerwünschte Padding, um Konsistenz zu gewährleisten */
}

.input-group .form-control, .input-group .btn, .input-group .dropdown-toggle {
  align-self: center; /* Zwingt jedes Element sich individuell in der Mitte auszurichten */
}

p {
  margin-bottom: 1px; /* Reduziert den Abstand unter allen <p>-Elementen */
}

.back-button {
  position: absolute;
  top: 70px;
  left: 40px;
  z-index: 1000;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 5px;
}

</style>