<template>
  <div class="map-orders-container">
    <!-- Navigációs sáv -->
    <navigation-bar></navigation-bar>

    <!-- Fő terület: Bal oldali szűrő és jobb oldali térkép -->
    <div class="content">
      <!-- Bal oldali szűrő -->
      <div class="sidebar">
        <div class="filter-section">
          <!-- Dátumtartomány -->
          <div class="form-group">
            <label for="date-from">Dátumtól:</label>
            <input
              id="date-from"
              type="date"
              class="form-control"
              v-model="mapFilters.dateFrom"
            />
          </div>
          <div class="form-group">
            <label for="date-to">Dátumig:</label>
            <input
              id="date-to"
              type="date"
              class="form-control"
              v-model="mapFilters.dateTo"
            />
          </div>

          <!-- Fuvarszervező legördülő lista -->
          <div class="form-group">
              <label for="fuvarszervezo">Fuvarszervező:</label>
              <select
                id="fuvarszervezo"
                class="form-control"
                v-model="mapFilters.fuvarszervezo"
              >
                <option value="" disabled>Kérjük, válasszon</option>
                <option
                  v-for="szervezo in fuvarszervezok"
                  :key="szervezo.id"
                  :value="szervezo.id"
                >
                  {{ szervezo.name }}
                </option>
              </select>

              
                <!-- Icons for update and reset -->
                <div class="button-group mt-2">
                  <button
                    class="btn btn-secondary btn-icon"
                    @click="fetchFuvarszervezok"
                    title="Aktualizál"
                  >
                    <i class="fas fa-sync"></i>
                  </button>
                  <button
                    class="btn btn-secondary btn-icon"
                    @click="resetFuvarszervezo"
                    title="Visszaállítás"
                  >
                    <i class="fas fa-undo"></i>
                  </button>
                </div>
            
            
            
            </div>


          <!-- Diszpécser legördülő lista -->
          <div class="form-group">
            <label for="diszpecser">Diszpécser:</label>
            <select
              id="diszpecser"
              class="form-control"
              v-model="mapFilters.diszpecser"
            >
              <option value="" disabled>Kérjük, válasszon</option>
              <option
                v-for="diszpecser in diszpecserek"
                :key="diszpecser.id"
                :value="diszpecser.id"
              >
                {{ diszpecser.name }}
              </option>
            </select>
            <!-- Visszaállítás gomb -->
            <button class="btn btn-secondary mt-2" @click="resetDiszpecser">
              Visszaállítás
            </button>
          </div>

          <!-- Frissítés gomb -->
          <button class="btn btn-primary btn-block mt-3" @click="updateMap">
            Frissítés
          </button>
        
        
          <!-- Felrakó kapcsoló -->
          <div class="form-group">
            <label for="felrako-switch" class="switch-label">
              Felrakók
              <input
                type="checkbox"
                id="felrako-switch"
                v-model="mapFilters.felrako"
              />
              <span class="slider"></span>
            </label>
          </div>

          <!-- Lerakó kapcsoló -->
          <div class="form-group">
            <label for="lerako-switch" class="switch-label">
              Lerakók
              <input
                type="checkbox"
                id="lerako-switch"
                v-model="mapFilters.lerako"
              />
              <span class="slider"></span>
            </label>
          </div>

          <!--  vonalak-->
          <div class="form-group">
            <label for="vonalak-switch" class="switch-label">
              Vonalak
              <input
                type="checkbox"
                id="vonalak-switch"
                v-model="mapFilters.vonalak"
              />
              <span class="slider"></span>
            </label>
          </div>


          <!-- Teherautók kapcsoló -->
          <div class="form-group">
            <label for="teherautok-switch" class="switch-label">
              Teherautók
              <input
                type="checkbox"
                id="teherautok-switch"
                v-model="mapFilters.teherautok"
              />
              <span class="slider"></span>
            </label>
          </div>
        
        
        </div>
      </div>







      <!-- Jobb oldali térkép -->
      <div class="map-area">
        <div id="map" style="height: 100%;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import L from "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
import "leaflet.awesome-markers";
//import "leaflet-polylinedecorator";
import axiosInstance from "@/axiosInstance";



export default {
  name: "MapOrders",
  data() {
    return {
      fuvarszervezok: [], // Initially empty, will be set dynamically
      diszpecserek: [
        { id: 1, name: "Nem lehet kiválasztani" }
        
      ],
      map: null,
      felrakoPoints: [], // Sicherstellen, dass felrakoPoints ein leeres Array ist
      felrakoLayerGroup: null, // LayerGroup für Felrakó-Marker
      lerakoPoints: [], // Array für die Lerakó-Daten
      lerakoLayerGroup: null, // LayerGroup für Lerakó-Marker
      vonalPoints: [], // Daten für Linien
      vonalLayerGroup: null // LayerGroup für Linien

    };
  },
  computed: {
    ...mapGetters(["getMapFilters"]),
    ...mapGetters(['getUserData']),
    mapFilters: {
      get() {
        return this.getMapFilters; // Direkt aus Vuex abrufen
      },
      set(value) {
        this.updateAllMapFilters(value); // Werte direkt im Store aktualisieren
      },
    },
    ceg_az() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    }
    

  },


  mounted() {
    if (!this.map) {
      console.error("Map instance is null. Ensure it is initialized properly.");

      this.map = L.map("map").setView([46.301645, 18.5000], 7);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);

       // Stelle sicher, dass die Karte vollständig geladen ist
       this.map.whenReady(() => {
            this.map.invalidateSize(); // Ensure proper rendering on load
            console.log("Map is ready");
            this.updateMap();
          });
    }
    
    this.fetchFuvarszervezok();
  },
  
  beforeUnmount() {
  if (this.map) {
    this.map.remove(); // Entfernt alle Event-Listener und die Karte
    this.map = null; // Karte auf null setzen
  }
},


  
  methods: {
    ...mapActions(["updateAllMapFilters"]),

    resetFuvarszervezo() {
    this.mapFilters.fuvarszervezo = ""; // Setzt den Wert zurück
  },

  

  resetDiszpecser() {
    this.mapFilters.diszpecser = ""; // Setzt den Wert für Diszpécser zurück
  },


  addMarkers() {
  if (this.mapFilters.felrako && Array.isArray(this.felrakoPoints) && this.felrakoPoints.length > 0) {
    this.felrakoPoints.forEach((point) => {
      const coords = point.gps.split(",").map((coord) => parseFloat(coord.trim())); // GPS in Koordinaten umwandeln

      const felrakoIcon = L.divIcon({
        className: "custom-marker",
        html: `<i class="fas fa-download" style="color: #1c35d6; font-size: 1.0rem;"></i>`,
        iconSize: [15, 15],
        iconAnchor: [5, 5],
      });

      L.marker(coords, { icon: felrakoIcon })
        .addTo(this.map)
        .bindPopup(`
          <b>Felrakó:</b> ${point.felrako}<br>
          <b>Hely Cég:</b> ${point.hely_ceg}<br>
          <b>Szöveg:</b> ${point.szoveg.replace(/\n/g, "<br>")}
        `);
    });
  } else {
    console.log("Keine Felrakó-Daten vorhanden oder Filter ist deaktiviert.");
  }
},






    updateMap() {
      console.log("Aktuelle Filter:", this.mapFilters);

      // Bestehende Marker entfernen
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker) this.map.removeLayer(layer);
      });

    
      this.mapFilters.felrako=false;
      this.mapFilters.lerako=false;
      this.mapFilters.vonalak=false;

     // Felrakó- und Lerakó-Punkte abrufen
      this.fetchFelrakoPoints();
      this.fetchLerakoPoints();
      this.fetchVonalPoints();

    },


    async fetchFelrakoPoints() {
      
      const selectedFuvarszervezo = this.fuvarszervezok.find(
        (szervezo) => szervezo.id === this.mapFilters.fuvarszervezo
      );

      // If no selection is made, use an empty string as the value for fsz
      const fszValue = selectedFuvarszervezo ? selectedFuvarszervezo.name : "*";

      const postData = {
        ceg_az: this.ceg_az,
        fsz: fszValue, // Use the found name or empty string
        datumtol: this.mapFilters.dateFrom,
        datumig: this.mapFilters.dateTo,
      };

  try {
    console.log("mapOrders.postData", postData);

    const url = "/megrendeles/map_felrako.php";
    const response = await axiosInstance.post(url, postData);

    if (response.status === 200 && response.data) {
      console.log("API Response:", response.data);
      this.felrakoPoints = response.data.data || []; // Speichern der API-Daten

      // LayerGroup verwenden, falls sie bereits existiert, ansonsten erstellen
      if (!this.felrakoLayerGroup) {
        this.felrakoLayerGroup = L.layerGroup(); // LayerGroup erstellen, aber nicht der Karte hinzufügen
      } else {
        this.felrakoLayerGroup.clearLayers(); // Bestehende Marker entfernen
      }

      // Neue Marker aus felrakoPoints hinzufügen
      this.felrakoPoints.forEach((point) => {
        const coords = point.gps.split(",").map((coord) => parseFloat(coord.trim()));

        const felrakoIcon = L.divIcon({
          className: "custom-marker",
          html: `<i class="fas fa-download" style="color: #1c35d6; font-size: 1.0rem;"></i>`,
          iconSize: [15, 15],
          iconAnchor: [5, 5],
        });

        const felrakoMarker = L.marker(coords, { icon: felrakoIcon }).bindPopup(`
          <b>Felrakó:</b> ${point.felrako}<br>
          <b>Cég:</b> ${point.hely_ceg}<br>
          ${point.szoveg.replace(/\n/g, "<br>")}
        `);

        // Marker zur bestehenden LayerGroup hinzufügen
        this.felrakoLayerGroup.addLayer(felrakoMarker);
      });
    } else {
      console.error("Fehler beim Abrufen der Felrakó-Daten:", response);
      this.felrakoPoints = []; // Fallback auf ein leeres Array
    }
  } catch (error) {
    console.error("API-Fehler:", error);
    this.felrakoPoints = []; // Fallback bei API-Fehler
  }
},

async fetchLerakoPoints() {
  const selectedFuvarszervezo = this.fuvarszervezok.find(
    (szervezo) => szervezo.id === this.mapFilters.fuvarszervezo
  );

  // If no selection is made, use an empty string as the value for fsz
  const fszValue = selectedFuvarszervezo ? selectedFuvarszervezo.name : "*";

  const postData = {
    ceg_az: this.ceg_az,
    fsz: fszValue, // Use the found name or empty string
    datumtol: this.mapFilters.dateFrom,
    datumig: this.mapFilters.dateTo,
  };


  try {
    console.log("mapOrders.postData (Lerako)", postData);

    const url = "/megrendeles/map_lerako.php"; // Beispiel-Endpoint
    const response = await axiosInstance.post(url, postData);

    if (response.status === 200 && response.data) {
      console.log("API Response (Lerako):", response.data);
      this.lerakoPoints = response.data.data || []; // Daten speichern

      // LayerGroup verwenden, falls sie bereits existiert, ansonsten erstellen
      if (!this.lerakoLayerGroup) {
        this.lerakoLayerGroup = L.layerGroup();
      } else {
        this.lerakoLayerGroup.clearLayers(); // Bestehende Marker entfernen
      }

      // Neue Marker hinzufügen
      this.lerakoPoints.forEach((point) => {
        const coords = point.gps.split(",").map((coord) => parseFloat(coord.trim()));

        const lerakoIcon = L.divIcon({
          className: "custom-marker",
          html: `<i class="fas fa-upload" style="color: #d61c35; font-size: 1.0rem;"></i>`,
          iconSize: [15, 15],
          iconAnchor: [5, 5],
        });

        const lerakoMarker = L.marker(coords, { icon: lerakoIcon }).bindPopup(`
          <b>Lerakó:</b> ${point.lerako}<br>
          <b>Cég:</b> ${point.hely_ceg}<br>
          ${point.szoveg.replace(/\n/g, "<br>")}
        `);

        this.lerakoLayerGroup.addLayer(lerakoMarker);
      });
    } else {
      console.error("Fehler beim Abrufen der Lerakó-Daten:", response);
      this.lerakoPoints = []; // Fallback auf ein leeres Array
    }
  } catch (error) {
    console.error("API-Fehler (Lerako):", error);
    this.lerakoPoints = []; // Fallback bei API-Fehler
  }
},

async fetchVonalPoints() {
   
  const selectedFuvarszervezo = this.fuvarszervezok.find(
    (szervezo) => szervezo.id === this.mapFilters.fuvarszervezo
      );

      // If no selection is made, use an empty string as the value for fsz
      const fszValue = selectedFuvarszervezo ? selectedFuvarszervezo.name : "*";

      const postData = {
        ceg_az: this.ceg_az,
        fsz: fszValue, // Use the found name or empty string
        datumtol: this.mapFilters.dateFrom,
        datumig: this.mapFilters.dateTo,
      };

  try {
    console.log("mapOrders.postData (Vonal)", postData);

    const url = "/megrendeles/map_vonal.php"; // Endpoint für Linien
    const response = await axiosInstance.post(url, postData);

    if (response.status === 200 && response.data) {
      console.log("API Response (Vonal):", response.data);
      this.vonalPoints = response.data.data || []; // Daten speichern

      // LayerGroup verwenden oder neu erstellen
      if (!this.vonalLayerGroup) {
        this.vonalLayerGroup = L.layerGroup();
      } else {
        this.vonalLayerGroup.clearLayers(); // Vorhandene Linien entfernen
      }

      // Linien erstellen und zur LayerGroup hinzufügen
      this.vonalPoints.forEach((point) => {
        const startCoords = point.gpsfel.split(",").map((coord) => parseFloat(coord.trim()));
        const endCoords = point.gpsle.split(",").map((coord) => parseFloat(coord.trim()));

        const polyline = L.polyline([startCoords, endCoords], {
          color: "blue", // Farbe der Linie
          weight: 3, // Dicke der Linie
          opacity: 0.8, // Transparenz der Linie
        }).bindPopup(`
          <b>Felrakó:</b> ${point.felrako}<br>
          <b>Lerakó:</b> ${point.lerako}<br>
          <b></b> ${point.szoveg.replace(/\n/g, "<br>")}
        `);

        this.vonalLayerGroup.addLayer(polyline); // Linie zur LayerGroup hinzufügen
      });
    } else {
      console.error("Fehler beim Abrufen der Vonal-Daten:", response);
      this.vonalPoints = []; // Fallback auf leeres Array
    }
  } catch (error) {
    console.error("API-Fehler (Vonal):", error);
    this.vonalPoints = []; // Fallback bei Fehler
  }
},

async fetchFuvarszervezok() {
      try {

        const postData = {
          ceg_az: this.ceg_az,
          datumtol: this.mapFilters.dateFrom,
          datumig: this.mapFilters.dateTo,
        };

        console.log("Fetching Fuvarszervezok...");

        const url = "/megrendeles/map_fsz.php"; // Endpoint für Linien
        const response = await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data) {
          console.log("Fuvarszervezok Response:", response.data);

          // Map the API response to the required structure
          this.fuvarszervezok = response.data.data.map((item, index) => ({
            id: index + 1, // Assigning a dynamic ID (can be improved if the API provides unique IDs)
            name: item.fsz,
          }));
        } else {
          console.error("Error fetching Fuvarszervezok data:", response);
        }
      } catch (error) {
        console.error("API Error while fetching Fuvarszervezok:", error);
      }
    },


resetFilters() {
    // Setzt alle relevanten Filter zurück
    this.mapFilters.felrako = false;
    this.mapFilters.lerako = false;
    this.mapFilters.vonalak = false;

    // Optionale Nachricht in der Konsole
    console.log("Alle Filter wurden auf 'false' gesetzt.");
  }


  },


  watch: {
  "mapFilters.felrako"(newVal) {
    // Überwachung des Felrako-Filters
    if (newVal) {
      // Layer hinzufügen, falls aktiviert
      if (this.felrakoLayerGroup && !this.map.hasLayer(this.felrakoLayerGroup)) {
        this.map.addLayer(this.felrakoLayerGroup);
      }
    } else {
      // Layer entfernen, falls deaktiviert
      if (this.felrakoLayerGroup && this.map.hasLayer(this.felrakoLayerGroup)) {
        this.map.removeLayer(this.felrakoLayerGroup);
      }
    }
  },

  "mapFilters.lerako"(newVal) {
    // Überwachung des Lerakó-Filters
    if (newVal) {
      // Layer hinzufügen, falls aktiviert
      if (this.lerakoLayerGroup && !this.map.hasLayer(this.lerakoLayerGroup)) {
        this.map.addLayer(this.lerakoLayerGroup);
      }
    } else {
      // Layer entfernen, falls deaktiviert
      if (this.lerakoLayerGroup && this.map.hasLayer(this.lerakoLayerGroup)) {
        this.map.removeLayer(this.lerakoLayerGroup);
      }
    }
  },


  "mapFilters.vonalak"(newVal) {
    if (newVal) {
      // Layer hinzufügen, falls aktiviert
      if (this.vonalLayerGroup && !this.map.hasLayer(this.vonalLayerGroup)) {
        this.map.addLayer(this.vonalLayerGroup);
      }
    } else {
      // Layer entfernen, falls deaktiviert
      if (this.vonalLayerGroup && this.map.hasLayer(this.vonalLayerGroup)) {
        this.map.removeLayer(this.vonalLayerGroup);
      }
    }
  },

 
  "mapFilters.dateFrom"(newVal, oldVal) {
    // Wenn sich das Startdatum ändert, Filter zurücksetzen
    console.log(`DatumVon geändert: ${oldVal} -> ${newVal}`);
    this.resetFilters();
  },
  "mapFilters.dateTo"(newVal, oldVal) {
    // Wenn sich das Enddatum ändert, Filter zurücksetzen
    console.log(`DatumBis geändert: ${oldVal} -> ${newVal}`);
    this.resetFilters();
  }




},



};
</script>
<style scoped>

.button-group {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Spacing between buttons */
  margin-top: 10px; /* Add some margin to the group */
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust the size of the buttons */
  height: 40px;
  padding: 0;
  border-radius: 50%; /* Make the buttons circular */
}

.btn-icon i {
  font-size: 16px; /* Icon size */
}



.map-orders-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* Content-Bereich: Sidebar und Karte */
.content {
  display: flex;
  flex: 1;
  height: calc(100vh - 56px); /* Abzug der Navigationshöhe */
  overflow: hidden;
}

/* Sidebar links */
.sidebar {
  width: 300px;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 20px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-section .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px; /* Abstand zwischen den Schaltern */
}

.filter-section label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-section select {
  padding: 5px;
  font-size: 14px;
}

.filter-section input[type="date"] {
  padding: 5px;
  font-size: 14px;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  position: relative;
}

.switch-label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  width: 34px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

input[type="checkbox"]:checked + .slider {
  background-color: #007bff;
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(14px);
}

/* Karte rechts */
.map-area {
  flex-grow: 1;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}
</style>
