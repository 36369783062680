import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'; // Importieren Sie Ihren Vuex Store

//import HomeView from '../views/HomeView.vue'
import LoginForm from '../views/LoginForm.vue'; 
import MenuForm from '../views/MenuForm.vue'; // Menü-Komponente
import NewOrder from '../views/newOrder.vue'; // Neue Bestellung Komponente
import osszesMegrendeles from '../views/osszesMegrendeles.vue'; // Neue Bestellung Komponente
import passwordChange from '../views/passwordChange.vue';
import newTruck from '../views/newTruck.vue';
import orderDetails from '../views/orderDetails.vue'; 
import OrderList from '../views/OrderList.vue'; 
import HelpPage from '@/views/HelpPage.vue';
import ChangeLog from '@/views/ChangeLog.vue'; // Pfad zur ChangeLog-Komponente
import editTruck from '@/views/editTruck.vue';
import UserSettings from '@/views/userSettings.vue';
import editOrder from '../views/editOrder.vue';
import SajatOsszes from '../views/SajatOsszes.vue'; // Neue Komponente hinzugefügt
import EmailList from '../views/EmailList.vue'; // Neue Komponente hinzugefügt
import WeeklyOrders from "../views/WeeklyOrders.vue";
import WeeklyTrucks from "../views/WeeklyTrucks.vue";
import MapOrders from "../views/MapOrders.vue";

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginForm
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuForm
  },
  {
    path: '/new_order',
    name: 'new_order',
     component: NewOrder,
    //component: () => import('@/views/NewOrder.vue'),
    meta: { requiresAuth: true } // Meta-Feld, um die Authentifizierung zu markieren
  },
  {
    path: '/editOrder',
    name: 'editOrder',
    component: editOrder
  },
  {
    path: '/OrderList',
    name: 'OrderList',
    component: OrderList
  },
  {
    path: '/EmailList',
    name: 'EmailList',
    component: EmailList
  },
  {
    path: '/osszesMegrendeles',
    name: 'osszesMegrendeles',
    component: osszesMegrendeles
  },
  {
    path: '/passwordChange',
    name: 'passwordChange',
    component: passwordChange
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: orderDetails
  },
  {
    path: '/newTruck',
    name: 'newTruck',
    component: newTruck
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpPage
  },
  {
    path: '/user-settings',
    name: 'userSettings',
    component: UserSettings,
    //meta: { requiresAuth: true } // Optional: Authentifizierung erforderlich, je nach Bedarf
  },
  {
    path: '/change-log',
    name: 'ChangeLog',
    component: ChangeLog
  },
  {
    path: '/edit-truck',
    name: 'editTruck',
    component: editTruck
  },
  {
    path: '/sajat-osszes',
    name: 'SajatOsszes',
    component: SajatOsszes
  },
  {
    path: "/weekly-orders",
    name: "WeeklyOrders",
    component: WeeklyOrders,
  },
  {
    path: "/weeklyTrucks",
    name: "WeeklyTrucks",
    component: WeeklyTrucks,
  },
  {
    path: "/MapOrders",
    name: "MapOrders",
    component: MapOrders,
  }
  
];






const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Navigation Guard zur Überprüfung der Berechtigungen
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Prüfen Sie, ob der Benutzer als Fuvarszervező authentifiziert ist
    if (store.state.userData.tipus === 'fuvarszervező') {
      next();
    } else {
      alert('Nincs jogosultsága új megrendelés rögzítéséhez');
      next(false); // Verhindert den Zugriff, wenn die Bedingung nicht erfüllt ist
    }
  } else {
    next(); // Erlaubt den Zugang, wenn keine speziellen Berechtigungen benötigt werden
  }
});


export default router
