<template>
  <nav 
  class="navbar navbar-expand-lg"
    :class="{'bg-light': ceg_az !== 2, 'bg-custom': ceg_az == 2}"
  >
    
    
    <div class="container">
      <!-- Logo hinzufügen -->
      <a class="navbar-brand" href="#">
        <img src="@/assets/bogos.png" alt="Firmenlogo" class="logo">
        <span class="ftr-text">FTR 0.1.62</span>
        <span class="fsz-text"> {{ fsz }}
        </span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          
          <li class="nav-item">
            <router-link class="nav-link" to="/menu">Start</router-link>
          </li>
        
       <!-- Összes megrendelés -->
       <li class="nav-item">
            <router-link class="nav-link" to="/osszesMegrendeles">
              <i class="fas fa-list-alt"></i> Napi összes
            </router-link>
          </li>
        
  <!-- Új megrendelés -->
  <li class="nav-item">
            <router-link class="nav-link" to="/new_order">
              <i class="fas fa-plus-circle"></i> Új megrendelés
            </router-link>
          </li>
        
          <!-- VIR -->
          <li class="nav-item" v-if="currentRouteName === 'osszesMegrendeles'">
            <a class="nav-link" href="#" @click.prevent="syncData">
              <i class="fas fa-sync-alt"></i> VIR
            </a>
          </li>

       


<!-- Neuer Menüeintrag für Saját Fuvarszervező -->
<li class="nav-item">
            <router-link class="nav-link" to="/sajat-osszes">
              <i class="fas fa-truck"></i> Heti összes
            </router-link>
          </li>

<!-- Térkép Menüpunkt -->
<li class="nav-item">
  <router-link class="nav-link" to="/MapOrders">
    <i class="fas fa-globe"></i> Térkép
  </router-link>
</li>


<!-- E-Mail Menüpunkt -->
<li class="nav-item">
  <router-link class="nav-link" to="/EmailList">
    <i class="fas fa-envelope"></i> Email
  </router-link>
</li>


         <!-- Change Log mit Icon -->
         <li class="nav-item">
            <router-link class="nav-link" to="/change-log">
              <i class="fas fa-book"></i> Napló
            </router-link>
          </li>

         <!-- Help -->
         <li class="nav-item">
            <router-link class="nav-link" to="/help">
              <i class="fas fa-question-circle"></i> Help
            </router-link>
          </li>


          <li class="nav-item">
          <router-link class="nav-link" to="/user-settings">
            <i class="fas fa-user-cog"></i> <!-- Icon anstelle von Text -->
          </router-link>
        </li>


        <li class="nav-item">
            <a class="nav-link" href="index.html">
              <i class="fas fa-sign-out-alt"></i> Kilép
            </a>
          </li>


        </ul>
      </div>
    </div>
  </nav>
</template>



<script>
import { mapGetters } from 'vuex';
import axiosInstance from '@/axiosInstance';

export default {
  computed: {
    ...mapGetters(['getUserData']),
    ...mapGetters(['getNapiDatum']),
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    },
    ceg_az() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    },
    // Hier holen wir den aktuellen Routen-Namen
    currentRouteName() {
      return this.$route.name;
    },
    // Hier holen wir den `az`, falls die Seite orderDetails aktiv ist
    currentAz() {
      return this.$route.params.az || 0;
    },
    formattedDate() {
      // Verwende das Datum aus dem Vuex Store, wenn verfügbar
      return this.getNapiDatum;
    }
  },
  methods: {
    navigateToChangeLog() {
      let table_name = '';
      let record_id = 0;
      let user = '';

      // Prüfen, welche Seite aktiv ist und die Parameter entsprechend setzen
      if (this.currentRouteName === 'orderDetails') {
        table_name = 'ftr_megrendeles';
        record_id = this.currentAz;
      } else if (this.currentRouteName === 'MenuForm') {
        user = this.fsz;
      }

      // Navigation zu ChangeLog mit den festgelegten Parametern
      this.$router.push({
        name: 'ChangeLog',
        query: {
          user: user,
          table_name: table_name,
          record_id: record_id
        }
      });
    },
    async syncData() {
      try {
  console.log('data sync start');
  const postData = {

    fsz: this.fsz,
    ceg_az: this.ceg_az,
    datum: this.formattedDate  // Verwendet das aktuell angezeigte Datum
  };

  console.log('syncData', postData);

  const response = await axiosInstance.post('/megrendeles/vir_sync.php', postData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200 && response.data.success) {
    alert('A kiválasztott napon a saját fuvarok szinkronizálása a VIR-be elkezdődött. Az adatok 1-2 percen belül megjelennek a VIR-ben.');
  } else {
    // Fehlermeldung im Detail anzeigen
    console.error('Sync error:', response.data);
    alert('Szinkronizációs hiba: ' + (response.data.message || 'Ismeretlen hiba') + '\nRészletek: ' + JSON.stringify(response.data));
  }
} catch (error) {
  // Detailliertere Fehlermeldung für das Catch-Block
  console.error('API hiba:', error);

  if (error.response) {
    // Server antwortete mit einem Statuscode, der außerhalb des Bereichs von 2xx liegt
    console.error('Server Antwort:', error.response.data);
    alert('Hiba az API kommunikáció során: ' + error.message + '\nSzerver válasz: ' + JSON.stringify(error.response.data));
  } else if (error.request) {
    // Die Anfrage wurde gesendet, aber es kam keine Antwort zurück
    console.error('Keine Antwort vom Server:', error.request);
    alert('Hiba az API kommunikáció során: A szerver nem válaszolt.\nRészletek: ' + error.message);
  } else {
    // Fehler bei der Anfrage selbst (z.B. fehlerhafte URL)
    console.error('Anfrage Fehler:', error.message);
    alert('Hiba az API kommunikáció során: ' + error.message);
  }
}


    }
  


  },
  name: 'NavigationBar'
};
</script>
  
<style scoped>
.logo {
  height: 30px;
  margin-right: 10px;
}

.ftr-text {
  font-size: 0.75em;
  margin-right: 30px;
}

.fsz-text {
  font-size: 1em;
  margin-left: auto;
}

.bg-light {
  background-color: #c8f4fc !important;
}

.bg-custom {
  background-color: #abf588 !important; /* Gelbe Farbe für ceg_az = 2 */
}

.navbar-nav {
  display: flex;
  flex-wrap: nowrap; /* Verhindert Zeilenumbrüche */
  justify-content: flex-end;
  align-items: center;
  gap: 15px; /* Abstand zwischen den Einträgen */
}

.nav-item {
  margin-right: 10px; /* Horizontaler Abstand zwischen Einträgen */
  white-space: nowrap; /* Verhindert Textumbrüche */
}

.nav-item .nav-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-item .nav-link i {
  font-size: 1.2em;
  margin-right: 8px; /* Abstand zwischen Icon und Text */
}

.nav-item .nav-link:hover {
  background-color: #f0f0f0; /* Hintergrundfarbe beim Hover */
  color: #007bff; /* Textfarbe beim Hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
}

.nav-item .nav-link:hover i {
  transform: scale(1.2); /* Icon leicht vergrößern bei Hover */
}

.navbar-brand {
  margin-right: 30px; /* Mehr Abstand zwischen Logo und Navigation */
}

.container {
  max-width: 100%; /* Volle Breite nutzen */
}
</style>
